import Encodings, { ValidationBodyRequest } from 'api/Encodings'
import { IdentifierStatus, IdentifierType, ProductionOrderRow, ReadIdentifierType, TmrTag } from 'api/types'
import { EncodingInitialType, EncodingConfig } from 'shared/RemoteConfig'
import RfidReader from 'shared/RfidReader'
import { navigate } from 'shared/router'
import { sleep } from 'shared/utils'
import { T, __ } from 'translations/i18n'

export type EncodingType = 'CustomChangeCode'
export default class EncodingProvider {
  static async writeUHFTags(
    encodingConfig: EncodingConfig,
    idfs: any[],
    productionOrderRow: ProductionOrderRow,
    nextEpcCallback: (newEpc: string) => void
  ) {
    await sleep(200)
    for await (const idf of idfs) {
      if (idf.type === 'UHFTag') {
        const newEpc = await Encodings.nextEpc(encodingConfig, productionOrderRow.product.code)
        if (!newEpc) {
          throw new Error(__(T.misc.unable_to_get_next_epc, { productCode: productionOrderRow.product.code }))
        }
        nextEpcCallback(newEpc.value)
        await sleep(50)
        const writeRes = await RfidReader.writeEpc(idf.code, newEpc.value)
        if (!writeRes || !writeRes.ok) {
          throw new Error(__(T.misc.unable_to_write, { code: idf.code, productCode: productionOrderRow.product.code }))
        }
        idf.code = newEpc.value
      }
    }
  }

  static async encode(data: ValidationBodyRequest, custom?: EncodingType, extraData?: any) {
    switch (custom) {
      case undefined:
        return Encodings.associate(data)
      default:
        return undefined
    }
  }

  static getOptionEncodingPage(value, encodingConfig: EncodingConfig) {
    switch (value) {
      case 'associate':
        navigate(`/encoding/:configCode`, { configCode: encodingConfig.code })
        break
      case 'verify':
        navigate(`/encoding/:configCode/verify`, { configCode: encodingConfig.code })
        break
      default:
        navigate(`/encoding/:configCode`, { configCode: encodingConfig.code })
        break
    }
  }

  static getEncodingMessageError(err, productCode, encodingInitialType?: EncodingInitialType) {
    let errorMessage
    const error = err.message ?? 'Codice Identifier non trovato'
    if (error.includes('ENCODING_ERROR.CERTILOGO_NOT_FOUND')) {
      err.message = 'Codice Identifier non trovato'
    }
    if (encodingInitialType === 'IDENTIFIER') {
      if (
        error.includes('ENCODING_ERROR.IDENTIFIER_ALREADY_ENCODED') ||
        error.includes('ENCODING_ERROR.CERTILOGO_ALREADY_ASSOCIATED')
      ) {
        errorMessage = __(T.error.identifier_already_encoded, { code: productCode })
      } else {
        errorMessage = __(T.error.identifier_not_found, { code: productCode })
      }
    } else {
      errorMessage = __(T.error.ean_not_found, { code: productCode })
    }
    return errorMessage
  }

  static onTagRead(tag: TmrTag, idfs, ignoreEpcs?) {
    let code = ''
    let tagType: IdentifierType

    if (tag.uid) {
      code = tag.uid
      tagType = 'NFCTag'
    } else {
      code = tag.epc
      tagType = 'UHFTag'
    }
    if (ignoreEpcs && ignoreEpcs.includes(code)) {
      throw new Error('Excluded epc')
    }
    let index = idfs.findIndex((i) => i.code === code && i.type === tagType)
    if (index === -1) {
      index = idfs.findIndex((i) => !i.code && i.type === tagType && i.status === 'waiting')
    } else if (idfs[index].status === 'confirmed' || idfs[index].status === 'error') {
      return
    }

    if (index >= 0) {
      idfs[index].code = code
      idfs[index].status = 'reading'
    } else {
      idfs.push({ code: code, status: 'reading', type: tagType })
    }
  }

  static getAssociationStatus(associateResponse, associating, validateResponse, writeEnabled?: boolean) {
    let associationStatus: IdentifierStatus = 'waiting'

    if (associateResponse) {
      if (associateResponse.success) {
        associationStatus = 'confirmed'
      } else {
        associationStatus = 'error'
      }
    }
    if (associating) {
      associationStatus = 'associating'
    }

    let status = validateResponse?.success === true ? associationStatus : 'hidden'
    if (validateResponse?.status_error === 'ENCODING_ERROR.PRODUCTION_ORDER_NOT_FOUND') {
      status = 'error'
    }
    const operationIdentifier = {
      type: writeEnabled ? 'write' : 'association',
      status: status,
    } as ReadIdentifierType

    return operationIdentifier
  }

  static checkErrorTag = (identifiers: any[] = []) =>
    identifiers.filter((i) => (i.type === 'UHFTag' || i.type === 'NFCTag') && i.code && i.status === 'error').length > 0
}
