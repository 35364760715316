import React, { Component } from 'react'
import {
  AntennaButton,
  Page,
  Spacer,
  TagCounter,
  Box,
  TextBox,
  Button,
  ItemsStatesRow,
  OperationReadingList,
  SelectItemModal,
} from 'components'
import { GroupedShipmentProduct, ShipmentParcel, TmrItem, TmrProduct, TmrZone } from 'api/types'
import { navigate, getMatchParams } from 'shared/router'
import { askUserConfirmation, showToast } from 'shared/utils'
import ShipmentProvider, { CheckListType } from 'ShipmentProvider'
import RfidReader from 'shared/RfidReader'
import { T, __ } from 'translations/i18n'
import Shipments from 'api/Shipments'
import AppStore from 'AppStore'
import { PageParams } from 'pages'
import Sounds from 'shared/Sounds'
import RemoteConfig, { InboundConfig } from 'shared/RemoteConfig'
import { inboundParcelBeforeConfirm, inboundParcelAfterConfirm } from 'pages/_extensions_/InboundExtensions'
import { Switch } from 'components/Switch'
import { HeaderDetail } from 'components/Header'

interface Params extends PageParams {
  inboundZone: TmrZone
  parcelCode: string
}

interface MatchParams extends Params {
  configCode: string
  parcelCode: string
}

interface State {
  parcels: ShipmentParcel[]
  checkListType: CheckListType
  groupedProducts: GroupedShipmentProduct[]
  loading: boolean
  errorMessage?: string
  deleteMode: boolean
  showSelectZoneModal: boolean
}

export default class extends Component<{}, State> {
  unexpectedFound = false

  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)

  params: MatchParams = {
    inboundZone: ShipmentProvider.getInboundStockZone(this.operation),
    ...getMatchParams(this.props),
  }

  state: State = {
    parcels: [],
    checkListType: 'ITEMS',
    groupedProducts: [],
    loading: true,
    deleteMode: false,
    showSelectZoneModal: false,
  }

  async componentDidMount() {
    try {
      const stockZones = AppStore.getZonesByType('STOCK')
      if (!this.params.inboundZone && !stockZones.length) throw new Error(__(T.error.no_stock_zones))
      if (!this.operation) throw new Error(__(T.error.no_operation_config))
      if (this.operation.readingMode !== 'rfid') throw new Error(__(T.error.not_supported_reading_mode))

      const {
        checkListType,
        inboundShipmentParcels,
        groupedProducts,
      } = await ShipmentProvider.fetchInboundShipmentParcels({ parcelCodes: [this.params.parcelCode] }, this.operation)

      await this.initRfidDevice(checkListType)
      this.setState({
        loading: false,
        checkListType,
        parcels: inboundShipmentParcels,
        groupedProducts,
      })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  async initRfidDevice(checkListType) {
    await RfidReader.initialize()

    // if (checkListType === 'TAGS') {
    //   RfidReader.onTagReadCallback = this.handleReceivedTags
    // } else {
    RfidReader.onDecodedItemCallback = this.onDecodedItemCallback
    RfidReader.setDecodeFunction(this.decodeFunction)
    // }
  }

  componentWillUnmount() {
    RfidReader.stop()
    RfidReader.clear()
  }

  decodeFunction = (epcs: string[]) => {
    if (!this.operation) throw new Error(__(T.error.undefined_operation_config))
    return Shipments.batchValidate<any>({
      configurationId: this.operation.id,
      identifiers: epcs,
    })
  }

  onDecodedItemCallback = async (itemMap) => {
    const { parcels: inboundShipmentParcels, groupedProducts, checkListType, deleteMode } = this.state
    let items: TmrItem[] = Object.values<TmrItem>(itemMap).filter((itm) => itm?.zone?.zoneType !== 'STOCK')
    if (deleteMode) {
      items.map((item) => this.removeItemFromReadings(item))
      this.forceUpdate()
      return
    }

    ShipmentProvider.processInboundItemsStates(items, this.operation)

    items = items.filter(
      (itm) => itm && !itm.__processedStates?.map((state) => state.processedState)?.includes('IGNORE')
    )

    if (checkListType === 'UPCS') {
      ShipmentProvider.processItemForUpcsChecklist(items, inboundShipmentParcels, groupedProducts)
    } else {
      ShipmentProvider.processItemForItemsChecklist(items, inboundShipmentParcels, groupedProducts)
    }

    const counters = ShipmentProvider.getCounters(
      inboundShipmentParcels[0],
      checkListType,
      checkListType === 'UPCS' ? 'ITEMS' : checkListType
    )

    if (this.operation.hasChecklist !== 'no' && !this.unexpectedFound && counters.unexpected > 0) {
      this.unexpectedFound = true
      Sounds.error()
    }

    if (this.operation.hasChecklist !== 'no' && counters.detected === counters.expected && counters.unexpected === 0) {
      Sounds.success()
    }

    this.forceUpdate()
  }

  handleReceivedTags = () => {}

  clear = () => {
    const { parcels: inboundShipmentParcels } = this.state
    RfidReader.clear()
    this.unexpectedFound = false
    const groupedProducts = ShipmentProvider.clearAllReceivingReadings(inboundShipmentParcels)
    this.setState({ groupedProducts, errorMessage: undefined })
  }

  setErrorMessage = (errorMessage: string) => {
    this.setState({ errorMessage })
  }

  confirmInbound = async () => {
    const { parcels: inboundShipmentParcels, checkListType } = this.state
    const parcel = inboundShipmentParcels.length ? inboundShipmentParcels[0] : undefined
    try {
      if (
        parcel?.detectedItems?.filter((item) =>
          item.__processedStates?.map((state) => state.processedState)?.includes('ERROR')
        ).length
      )
        throw new Error(__(T.error.items_in_error_found))

      if (this.operation.hasChecklist === 'yes') {
        const confirmed = await ShipmentProvider.processShipmentStatus(this.operation, this.setErrorMessage, parcel)
        if (!confirmed) return
      }

      if (!this.params.inboundZone) {
        this.setState({ showSelectZoneModal: true })
        return
      }

      // extension point "beforeConfirm"
      await inboundParcelBeforeConfirm(inboundShipmentParcels!, this.operation)

      await ShipmentProvider.confirmInbound(
        inboundShipmentParcels,
        this.operation.id,
        this.params.inboundZone.id,
        checkListType
      )

      // extension point "afterConfirm"
      await inboundParcelAfterConfirm(parcel!, this.operation)

      showToast({
        title: __(T.misc.success),
        description: __(T.messages.inbound_success),
        status: 'success',
      })
      this.navigateBack()
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  navigateBack = () => {
    const { parcels: inboundShipmentParcels } = this.state
    const parcel = inboundShipmentParcels.length ? inboundShipmentParcels[0] : undefined

    if (this.operation.inboundMode === 'shipment') {
      navigate('/inbound/:configCode/:shippingCode/parcels', {
        configCode: getMatchParams(this.props).configCode,
        shippingCode: parcel?.header?.shippingCode,
      })
      return
    }

    if (this.operation.hasChecklist === 'no') {
      navigate('/inbound/:configCode/create', { configCode: this.operation?.code })
    } else {
      navigate('/inbound/:configCode', { configCode: getMatchParams(this.props).configCode })
    }
  }

  removeItemFromReadings = (item: TmrItem) => {
    const { parcels: inboundShipmentParcels, groupedProducts } = this.state
    if (!item || item.id === undefined) return
    ShipmentProvider.removeItemsForItemsChecklist([item], inboundShipmentParcels, groupedProducts)
    const itemIdentifiers = item.itemIdentifiers.map((id) => id.code)
    RfidReader.removeTags(itemIdentifiers)
    this.forceUpdate()
  }

  removeProduct = (product: TmrProduct) => {
    const { parcels: inboundShipmentParcels, groupedProducts } = this.state
    if (!product || product.code === undefined) return
    const allDetectedItems = inboundShipmentParcels.flatMap((par) => par.detectedItems)
    const itemsToRemove = allDetectedItems.filter((itm) => itm.productCode === product.code)
    ShipmentProvider.removeItemsForItemsChecklist(itemsToRemove, inboundShipmentParcels, groupedProducts)
    const allItemsIdentifiers = itemsToRemove.flatMap((itm) => itm.itemIdentifiers)
    const itemIdentifiers = allItemsIdentifiers.map((id) => id.code)
    RfidReader.removeTags(itemIdentifiers)
    this.forceUpdate()
  }

  onDeleteToggle = (checked: boolean) => {
    const { parcels: inboundShipmentParcels } = this.state
    RfidReader.clear()
    if (checked === false) RfidReader.addTags(ShipmentProvider.getShipmentsTags(inboundShipmentParcels))
    this.setState({ deleteMode: checked })
  }

  selectStockZone = async (zone: TmrZone) => {
    const confirmed = await askUserConfirmation(
      __(T.misc.confirm),
      __(T.messages.are_you_sure_confirm_inbound_stock_zone, { stock_zone: zone.description ?? zone.code })
    )
    if (!confirmed) return

    await AppStore.saveDefaultStockZone(zone)
    this.params.inboundZone = zone
    this.setState({ showSelectZoneModal: false })

    await this.confirmInbound()
  }

  render() {
    const {
      parcels: inboundShipmentParcels,
      groupedProducts,
      loading,
      checkListType,
      errorMessage,
      deleteMode,
      showSelectZoneModal,
    } = this.state
    const parcel = inboundShipmentParcels.length ? inboundShipmentParcels[0] : undefined
    const shippingCode = parcel?.header?.shippingCode
    const parcelCode = parcel?.header?.parcelCode
    const originPlace = parcel?.header?.originPlace?.description || parcel?.header?.originPlace?.code
    const { detected, expected, unexpected } = ShipmentProvider.getCounters(
      parcel,
      checkListType,
      checkListType === 'UPCS' ? 'ITEMS' : checkListType
    )

    const pageTitle = this.operation?.description ?? __(T.titles.inbound)

    const details: HeaderDetail[] = []

    if (this.operation.hideShipmentInput !== 'yes') {
      details.push({ label: __(T.misc.shipment), value: shippingCode })
    }

    if (this.operation.hideParcelInput !== 'yes') {
      details.push({ label: __(T.misc.parcel), value: parcelCode })
    }

    if (this.operation.hasChecklist === 'yes') {
      details.push({ label: __(T.misc.origin), value: originPlace })
    }

    return (
      <Page
        title={pageTitle}
        onBackPress={this.navigateBack}
        loading={loading}
        header={{
          details,
        }}
        enableEmulation
      >
        <Page.Sidebar style={{ overflow: 'auto' }}>
          <Box flex style={{ overflow: 'auto' }}>
            <TagCounter detected={detected} expected={expected} unexpected={unexpected} />
            <AntennaButton style={{ backgroundColor: 'white' }} onClear={this.clear} hideClear={detected === 0} />
            <Spacer />
            <Switch
              onChange={this.onDeleteToggle}
              check={deleteMode}
              name="deleteMode"
              label={__(T.misc.remove_mode)}
              containerStyle={{ flexDirection: 'row-reverse', justifyContent: 'center' }}
              switchSize="lg"
              labelSize={20}
            />
            <Spacer />
            <TextBox text={errorMessage} type="error" />
            <Spacer />
          </Box>
          <Button
            title={__(T.misc.confirm_parcel)}
            onClick={this.confirmInbound}
            variant={errorMessage ? 'secondary' : 'primary'}
          />
        </Page.Sidebar>

        <Page.Content>
          <ItemsStatesRow
            items={parcel?.detectedItems as TmrItem[]}
            onItemDeleteCallback={this.operation.removeMode !== 'none' ? this.removeItemFromReadings : undefined}
          />
          <OperationReadingList
            operation={this.operation}
            items={parcel?.detectedItems as TmrItem[]}
            removeItemCallback={this.removeItemFromReadings}
            groupedProducts={groupedProducts}
            removeProductCallback={this.removeProduct}
            loading={loading}
          />
        </Page.Content>
        <SelectItemModal
          onSelect={(item) => this.selectStockZone(item[0])}
          field="code"
          valueField="description"
          options={AppStore.getZonesByType('STOCK')}
          selected={[AppStore.defaultStockZone]}
          title={__(T.titles.select_stock_zone)}
          searchable
          visible={showSelectZoneModal}
          onClose={() => this.setState({ showSelectZoneModal: false })}
        />
      </Page>
    )
  }
}
