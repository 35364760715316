export default {
  titles: {
    encoding: 'Encoding',
    inbound: 'Inbound',
    user_info: 'User Info',
    settings: 'Settings',
    reset_daily_items: 'Reset daily items?',
    last_parcels: 'Last Parcels',
    select_item: 'Select Item',
    select_workstation: 'Select workstation',
    select_language: 'Select language',
    transfer_to_zone: 'Transfer to zone',
    support: 'Support',
    select_printer: 'Select printer',
    shipment_to_confirm: 'Shipment to Confirm',
    select_stock_zone: 'Select Stock Zone',
    pallet_creation: 'Pallet Creation',
    pallet_package: 'Package {{orderNumber}}',
  },
  fields: {
    current_location: 'Current Location',
    identified_item: 'Identified Item',
    ignored_items: 'Ignored Items',
    error_items: 'Error Items',
    warning_items: 'Warning Items',
    pallet_code: 'Pallet Code',
    carrier_code: 'Carrier Code',
    carrier_desc: 'Carrier Desc',
  },
  misc: {
    save: 'Save',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    user: 'User',
    workstation: 'Workstation',
    username: 'Username',
    name: 'Name',
    password: 'Password',
    login: 'Login',
    encoding: 'Encoding',
    ean_code: 'EAN Code',
    certilogo: 'Certilogo',
    production_order: 'Production Order',
    enter_the_necessary_fields: 'Enter the necessary fields to start the operation',
    associated_items: 'Associated Items',
    associate: 'Associate',
    verify: 'Verify',
    reading: 'Reading',
    association: 'Association',
    waiting: 'Waiting',
    writing: 'Writing',
    retry: 'Retry',
    writing_association: 'Writing and association',
    confirmed: 'Confirmed',
    unable_to_write: `Unable to write epc tag : {{code}} for product: {{productCode}}`,
    unable_to_get_next_epc: `Unable to get next epc for: {{productCode}}`,
    unable_to_connect_to_workstation: 'Unable to connect to workstation',
    tag_info: 'Tag Info',
    inbound: 'Inbound',
    outbound: 'Outbound',
    stop: 'Stop',
    start: 'Start',
    pause: 'Pause',
    clear: 'Clear',
    tag_encoded_today: 'Tag Encoded today',
    ready_to_receive: 'Ready to receive',
    change_password: 'Change Password',
    logout: 'Logout',
    are_you_sure_to_logout: 'You really want to log out?',
    settings: 'Settings',
    roles: 'Roles',
    email: 'Email',
    place: 'Place',
    items: 'Items',
    description: 'Description',
    color: 'Color',
    size: 'Size',
    total_reads: 'TOTAL READS',
    save_to_list: 'Save to list',
    destination: 'Destination',
    origin: 'Origin',
    new_parcel: 'New Parcel',
    status: 'Status',
    parcel: 'Parcel',
    parcels: 'Parcels',
    shipment: 'Shipment',
    creation_date: 'Creation Date',
    search: 'Search',
    detail: 'Detail',
    quantity: 'Quantity ({{quantity}})',
    style: 'Style',
    material: 'Material',
    shipment_code_auto: 'Shipment code (auto)',
    parcel_code_auto: 'Parcel code (auto)',
    next: 'Next',
    parcels_to_receive: 'Parcels to receive',
    confirm_parcel: 'Confirm Parcel',
    image_missing: 'IMAGE MISSING',
    loading: 'Loading...',
    unknown_product: 'Unknown Product',
    unknown_tag: 'Unknown Tag',
    invalid_tag: 'Invalid Tag',
    stock: 'Stock',
    valid: 'Valid',
    unknown: 'Unknown',
    actions: 'Actions',
    reprint: 'Reprint',
    product: 'Product',
    report_repair: 'Report Repair',
    reason: 'Reason',
    request_code_change: 'Request Code Change',
    enable_workstation_emulation: 'Enable Workstation emulation',
    filter: 'Filter',
    language: 'Language',
    warning: 'Warning',
    success: 'Success',
    info: 'Info',
    checklist: 'Checklist',
    optional: 'Optional',
    phone: 'Phone',
    printer: 'Printer',
    new_shipment: 'New Shipment',
    upc: 'UPC',
    model_part_color_size: 'Model / Part / Color / Size',
    confirm_shipment: 'Confirm Shipment',
    receive: 'Receive',
    delete_association: 'Delete Association',
    open: 'Open',
    identifier: 'Identifier',
    are_you_sure: 'Are you sure to proceed?',
    remove_mode: 'Remove mode',
    locate_on_handheld: 'Locate on Handheld',
    country: 'Country',
    carrier: 'Carrier',
    tracking: 'Tracking',
    order: 'Order',
  },
  error: {
    error: 'Error',
    forbidden: 'Forbidden',
    not_found: 'Not Found',
    unauthorized: 'Unauthorized',
    too_many_tags_read: 'Too many tags read (expected {{exptected}} read {{read}})',
    too_many_checklist_types: 'Too many checklist types. Make sure to select parcels with same checklist type.',
    rfid_discrepancies_found: 'RFID Discrepancies found, clear all and try again or confirm with discrepancies',
    certilogo_not_found: 'Certilogo "{{code}}" not found',
    identifier_not_found: 'Identifier "{{code}}" not found',
    ean_not_found: 'EAN "{{code}}" not found',
    production_order_not_found: 'Production Order "{{code}}" not found',
    no_stock_zones: 'No Stock zones found',
    no_operation_config: 'Operation configuration not found',
    not_supported_reading_mode: 'Reading mode not supported. Only RFID reading mode is supported.',
    undefined_operation_config: 'Operation configuration cannot be undefined',
    certilogo_already_encoded: 'Certilogo "{{code}}" already encoded',
    identifier_already_encoded: 'Identifier "{{code}}" already encoded',
    tag_not_found: 'Tag not found',
    tag_unassociated: 'Tag unassociated',
    tag_type_unexpected: 'Unexpected tag type',
    tag_already_associated: 'Tag already associated',
    tags_not_associated: 'One or more tags are not associated',
    tags_mismatch: 'Tags mismatch',
    workstation_could_not_be_started: 'Workstation could not be started',
    authentication_failed: 'Authentication failed',
    destination_field_required: 'Destination field required',
    unable_to_connect_to_rfid_reader: 'Unable to connect to RFID Reader. Check that the device is turned On.',
    items_in_error_found: 'Items in error found',
    item_not_in_inbound: 'Item not in inbound',
    item_not_in_stock: 'Item not in stock',
    item_in_another_parcel: 'Item in another parcel',
    item_in_another_production_order: 'Item in another production order',
    field_is_mandatory: '{{field}} is mandatory.',
    click_error: 'Click Error Found',
    no_error: 'No error was found',
  },
  messages: {
    no_workstations_found: 'No Workstations Found',
    no_workstation_selected: 'No Workstation selected',
    are_you_sure_to_reset_daily_items: 'Are you sure you want to reset the daily items?',
    bring_an_item_to_antenna: 'Bring an item close to the antenna',
    press_start_to_read: 'Press Start button to \nstart reading RFID products',
    paste_comma_separeted_tags: 'Paste comma separated tags then press return...',
    connection_workstation_progress: 'Connection to workstation in progress..',
    operation_success: 'Operation successfully confirmed',
    outbound_success: 'Outbound successfully confirmed',
    inbound_success: 'Inbound successfully confirmed',
    transfer_to_zone_success: 'Transfer To Zone successfully confirmed',
    list_empty: 'List is empty',
    looks_like_anything_in_list: "Looks like you don't have anything in your list",
    cant_confirm_with_missing: "Can't confirm with missing",
    products_missing: 'Missing products found',
    cant_confirm_with_overqty: "Can't confirm with over quantity",
    products_overqty: 'Products over quantity present',
    cant_confirm_with_unexpected: "Can't confirm with unexpected",
    products_unexpected: 'Unexpected products present',
    are_you_sure_confirm_inbound_stock_zone: 'Are you sure to confirm the operation with stock zone "{{stock_zone}}"?',
  },
  placeholder: {
    search_shipment_parcel: 'Search Shipment or Parcel',
    search_product_parcel: 'Search Product or Parcel',
  },
  confirm: {
    discrepancy_title: 'Parcel with discrepancies',
    discrepancy_message: 'Products with discrepancies were found. Are you sure you want to confirm the parcel?',
    confirm_delete_association: 'Are you sure you want to delete this item association?',
  },
  labels: {
    draft: 'Draft',
    ready_to_send: 'Ready to send',
    send_await: 'Pending',
    outbound_error: 'Outbound error',
    sent: 'Sent',
    in_inbound: 'In Inbound',
    receive_await: 'Pending',
    ready_to_receive: 'Ready to receive',
    inbound_error: 'Inbound error',
    received: 'Received',
    in_transit: 'In transit',
  },
}
