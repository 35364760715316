import React, { Component } from 'react'
import {
  Page,
  TagCounter,
  AntennaButton,
  ItemList,
  Box,
  Button,
  Spacer,
  TextBox,
  PalletStatus,
  KeyValueRow,
} from 'components'
import { Pallet, PalletCreationData, CustomTmrItem } from 'api/types'
import { getMatchParams, navigate } from 'shared/router'
import { openModal, showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import Pallets from 'api/Pallets'
import QRCode from 'react-qr-code'
import RfidReader from 'shared/RfidReader'

interface State {
  items: CustomTmrItem[]
  pallet?: Pallet
}

export default class CustomPalletReading extends Component<{}, State> {
  palletCode = getMatchParams(this.props)?.palletCode

  state: State = {
    items: [],
  }

  componentWillUnmount() {
    RfidReader.stop()
    RfidReader.clear()
  }

  onItemDecoded = (item) => {
    const { items } = this.state
    item.parcelStatus = undefined
    item.parcelError = undefined
    items.unshift(item)
    this.setState({ items })
  }

  clear = () => this.setState({ items: [], pallet: undefined })

  openQrCodeModal = () => {
    const { items } = this.state
    const koEpcs = items
      .filter(
        (itm) =>
          itm.parcelStatus?.toUpperCase() === 'KO' &&
          (itm.itemIdentifiers?.find((id) => id.identifierType === 'UHFTag') || itm.epc)
      )
      .map((itm) =>
        itm.itemIdentifiers ? itm.itemIdentifiers.find((id) => id.identifierType === 'UHFTag')!.code : itm.epc
      )

    openModal({
      body: (
        <Box>
          <QRCode value={koEpcs.join()} />
        </Box>
      ),
      title: __(T.misc.locate_on_handheld),
      containerStyle: { alignItems: 'center' },
    })
  }

  confirmPallet = async () => {
    try {
      await RfidReader.stop()
      const identifiers = this.state.items.map((itm) =>
        itm?.itemIdentifiers && itm.itemIdentifiers[0].code ? itm.itemIdentifiers[0].code : itm.epc
      )
      const data: PalletCreationData = { palletNumber: this.palletCode, tagList: identifiers }
      const pallet = await Pallets.confirmPallet(data)
      this.processPallet(pallet)
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  processPallet = (pallet: Pallet) => {
    const { items } = this.state
    const okItems: CustomTmrItem[] = []
    const koItems: CustomTmrItem[] = []
    pallet.parcels.forEach((parcel) => {
      const item = items.find((itm) =>
        itm.itemIdentifiers ? itm.itemIdentifiers.map((id) => id.code).includes(parcel.tag) : itm.epc === parcel.tag
      )
      if (item) {
        item.country = parcel.country
        item.carrierCode = parcel.carrierCode
        item.carrierDesc = parcel.carrierDesc
        item.trackingCode = parcel.tracking
        item.parcelStatus = parcel.parcelStatus
        item.parcelError = parcel.parcelError ?? undefined

        if (item.parcelStatus?.toUpperCase() === 'OK') {
          okItems.push(item)
        } else {
          koItems.push(item)
        }
      }
    })
    this.setState({ items: [...koItems, ...okItems], pallet })
  }

  onAntennaStart = () => {
    if (this.state.pallet) {
      RfidReader.clear()
      this.clear()
    }
  }

  renderHeaderRight = () => {
    const { items, pallet } = this.state
    if (!pallet) return <></>

    const okItems = items.filter((itm) => itm.parcelStatus?.toUpperCase() === 'OK').length
    const koItems = items.filter((itm) => itm.parcelStatus?.toUpperCase() === 'KO').length

    return (
      <>
        {!!pallet.carrierDesc && (
          <KeyValueRow
            key="carrierDesc"
            variant="raw"
            label={__(T.misc.carrier)}
            value={pallet.carrierDesc}
            valueStyle={{ whiteSpace: 'normal' }}
            style={{ marginLeft: 15, maxWidth: 400 }}
          />
        )}
        {koItems > 0 && (
          <Box ph={15}>
            <PalletStatus status="KO" quantity={koItems} />
          </Box>
        )}
        {okItems > 0 && (
          <Box ph={15}>
            <PalletStatus status="OK" quantity={okItems} />
          </Box>
        )}
      </>
    )
  }

  render() {
    const { items, pallet } = this.state
    const okItems = items.filter((itm) => itm.parcelStatus?.toUpperCase() === 'OK').length
    return (
      <Page
        title={__(T.titles.pallet_creation)}
        enableEmulation
        onBackPress={() => navigate('/pallet')}
        header={{ details: [{ label: __(T.fields.pallet_code), value: this.palletCode }] }}
        headerRight={this.renderHeaderRight()}
      >
        <Page.Sidebar>
          <TagCounter detected={items.length} />
          <AntennaButton
            onClear={this.clear}
            onItemDecoded={this.onItemDecoded}
            hideClear={items.length === 0}
            onStart={this.onAntennaStart}
          />
          <Spacer />
          {!!pallet?.palletError && <TextBox text={pallet.palletError} title={__(T.error.click_error)} type="error" />}
          {okItems == items.length && items.length > 0 && !pallet?.palletError && (
            <TextBox text={__(T.error.no_error)} type="success" />
          )}
          <Box flex />

          {pallet && (
            <Button title={__(T.misc.locate_on_handheld)} variant="secondary" onClick={this.openQrCodeModal} />
          )}
          {!pallet && items.length > 0 && (
            <>
              <Spacer />
              <Button title={__(T.misc.confirm)} onClick={this.confirmPallet} />
            </>
          )}
        </Page.Sidebar>

        <Page.Content>
          <ItemList items={items} />
        </Page.Content>
      </Page>
    )
  }
}
