import React, { Component } from 'react'
import { Button, List, Input, Page, Select, Spacer, Box, ShipmentParcelRow, Icons } from 'components'
import { navigate } from 'shared/router'
import { ShipmentParcelPreview } from 'api/types'
import OutboundShipments from 'api/OutboundShipments'
import { showToast } from 'shared/utils'
import ShipmentProvider from 'ShipmentProvider'
import { T, __ } from 'translations/i18n'
import { OutboundConfig } from 'shared/RemoteConfig'

interface State {
  data: ShipmentParcelPreview[]
  query: string
  destinationFilter?: ShipmentParcelPreview
  destinations?: ShipmentParcelPreview[]
  loading: boolean
}

interface Props {
  operation: OutboundConfig
}

export default class OutboundByParcel extends Component<Props, State> {
  operation = this.props.operation

  state: State = {
    data: [],
    query: '',
    loading: true,
  }

  componentDidMount() {
    OutboundShipments.parcelCounters({
      parcelStates: ['DRAFT', 'IN_TRANSIT', 'SEND_AWAIT', 'OUTBOUND_ERROR'],
      operationId: this.operation.id,
    })
      .then((data) => {
        this.setState({ loading: false, data: data ?? [], destinations: ShipmentProvider.getDestinations(data) })
      })
      .catch((err) => {
        this.setState({ loading: false, data: [] })
        showToast({
          title: __(T.error.error),
          description: err.message ?? 'Generic error',
          status: 'error',
        })
      })
  }

  onRowClick = (itm: ShipmentParcelPreview) => {
    if (itm.header.parcelState === 'DRAFT') {
      navigate('/outbound/:configCode/reading/:parcelCode', {
        configCode: this.operation?.code,
        parcelCode: itm.header.parcelCode,
      })
    } else {
      navigate('/outbound/:configCode/detail/:parcelCode', {
        configCode: this.operation?.code,
        parcelCode: itm.header.parcelCode,
      })
    }
  }

  onInputEnter = (input: string) => {
    const { data } = this.state
    this.setState({ query: input })
    const filtered = data.filter(this.filterResults)

    if (filtered.length === 1) {
      this.onRowClick(filtered[0])
    }
  }

  filterResults = (result: ShipmentParcelPreview) =>
    (result.header.parcelCode.toLowerCase().includes(this.state.query.toLowerCase()) ||
      result.header.shippingCode.toLowerCase().includes(this.state.query.toLowerCase())) &&
    ((this.state.destinationFilter &&
      result.header.destinationPlace.code === this.state.destinationFilter?.header?.destinationPlace?.code) ||
      !this.state.destinationFilter)

  render() {
    const { data, destinations, loading } = this.state
    const filtered = data.filter(this.filterResults)

    return (
      <Page title={this.operation?.description ?? 'Outbound'}>
        <Page.Content bgGrey>
          <Page.TopBar>
            <Input
              startFocus
              placeholder={__(T.placeholder.search_shipment_parcel)}
              onChange={(query) => this.setState({ query })}
              image={<Icons.Barcode />}
              style={{ width: 350 }}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Select
              containerStyle={{ width: 250 }}
              borderColor="transparent"
              transparent
              onSelect={(itm) => this.setState({ destinationFilter: itm })}
              options={destinations ?? []}
              placeholder={__(T.misc.destination)}
              config={{
                value: 'header.destinationPlace.code',
                label: 'header.destinationPlace.description',
                secondaryLabel: 'header.destinationPlace.code',
              }}
            />
            {this.operation.canCreateParcel === 'yes' && (
              <>
                <Box flex />
                <Button
                  title={__(T.misc.new_parcel)}
                  onClick={() => navigate('/outbound/:configCode/create', { configCode: this.operation?.code })}
                />
              </>
            )}
          </Page.TopBar>

          <List
            loading={loading}
            title={`${__(T.titles.last_parcels)} (${filtered.length})`}
            renderItem={(itm) => (
              <ShipmentParcelRow type="outbound" shipmentParcel={itm} onRowClick={this.onRowClick} />
            )}
            data={filtered}
          />
        </Page.Content>
      </Page>
    )
  }
}
