import { ApisauceConfig } from 'apisauce'
import api, { responseErrorCheck } from './api'
import TmrBaseResource from './TmrBaseResource'
import { Pallet, PalletCreationData } from './types'

export default class Pallets extends TmrBaseResource {
  static endpoint = '/pallets'

  static confirmPallet(data: PalletCreationData, requestConfig?: ApisauceConfig) {
    // const response = {
    //   palletNumber: '8198284129',
    //   carrierCode: '000070',
    //   carrierDesc: 'UNITED PARCEL SERVICE ITALIA S.R.L. (UPS)',
    //   palletStatus: 'KO',
    //   palletError: 'Colli non conformi',
    //   parcels: [
    //     {
    //       tag: 'E280689000005000C9E20DC9',
    //       order: 'P00784451',
    //       tracking: '00340434630670000552',
    //       country: 'IT',
    //       carrierCode: '000070',
    //       carrierDesc: 'UNITED PARCEL SERVICE ITALIA S.R.L. (UPS)',
    //       parcelStatus: 'OK',
    //       parcelError: null,
    //     },
    //     {
    //       tag: 'E280689000005000C9E20DC0',
    //       order: 'P00784451',
    //       tracking: '00340434630670000552',
    //       country: 'IT',
    //       carrierCode: '000070',
    //       carrierDesc: 'UNITED PARCEL SERVICE ITALIA S.R.L. (UPS)',
    //       parcelStatus: 'KO',
    //       parcelError: 'Collo non conforme al profilo TNT1 - Pagamento BLOCCATO',
    //     },
    //   ],
    // }
    // return response
    const customTimeout = 30000
    return api
      .post<Pallet>(
        `${this.endpoint}/confirmpallet`,
        data,
        requestConfig ? { ...requestConfig, timeout: customTimeout } : { timeout: customTimeout }
      )
      .then(responseErrorCheck)
      .then((res) => res)
  }
}
