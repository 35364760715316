/* eslint-disable no-case-declarations */
import { ApisauceConfig } from 'apisauce'
import { EncodingInitialType, EncodingConfig } from 'shared/RemoteConfig'
import Sounds from 'shared/Sounds'
import { T, __ } from 'translations/i18n'
import api, { responseErrorCheck } from './api'
import { ProductionOrder, ReadIdentifierType, TmrProduct, ItemIdentifier, ProductionOrderRow } from './types'

type ProductionOrderRowSearchParam = {
  orderCode?: string
  productCode?: string
  identifier?: string
  productionOrder?: ProductionOrder
}

export type ValidationBodyRequest = {
  productionOrderId?: string
  productId: string
  identifiers: {
    code: string
    type: string
  }[]
  configurationId: string
}

export default class Encodings {
  static getInfoProductionOrder(encodingConfig: EncodingConfig, code: string, requestConfig?: ApisauceConfig) {
    return api
      .get<ProductionOrder>(`/operations/encoding/info/${encodingConfig.id}/order/${code}`, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static getInfoProduct(encodingConfig: EncodingConfig, code: string, requestConfig?: ApisauceConfig) {
    return api
      .get<TmrProduct>(`/operations/encoding/info/${encodingConfig.id}/product/${code}`, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static getInfoItemIdentifier(encodingConfig: EncodingConfig, code: string, requestConfig?: ApisauceConfig) {
    return api
      .get<ItemIdentifier>(`/operations/encoding/info/${encodingConfig.id}/identifier/${code}`, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static nextEpc(encodingConfig: EncodingConfig, productCode: string, tid?: string, tagRole?: string) {
    return api
      .get<{ value: string }>(`/operations/encoding/nextEpc`, {
        productCode,
        tid,
        tagRole,
        configurationId: encodingConfig.id,
      })
      .then(responseErrorCheck)
      .then((res) => res)
  }

  // static async getProductionOrderRows(
  //   searchProductionOrderSetting: boolean,
  //   orderCode,
  //   productCode,
  //   requestConfig?: ApisauceConfig
  // ) {
  static async getProductionOrderRows(
    encodingConfig: EncodingConfig,
    searchProductionOrderSetting: EncodingInitialType,
    params: ProductionOrderRowSearchParam,
    requestConfig?: ApisauceConfig
  ) {
    const { productionOrder, productCode } = params
    let data
    switch (searchProductionOrderSetting) {
      case 'ORDER':
        const orderRows = productionOrder?.rows
        const productOrderRow = orderRows?.find((row) => row.product.code === productCode)
        if (!productOrderRow) throw new Error('Production Order Row not found')
        productOrderRow.order = productionOrder!
        data = productOrderRow
        break
      case 'PRODUCT':
        const product = await this.getInfoProduct(encodingConfig, productCode!, requestConfig)
        if (!product) throw new Error()
        data = { product }
        break
      case 'IDENTIFIER':
        const identifier = await this.getInfoItemIdentifier(encodingConfig, productCode!, requestConfig)
        if (!identifier) throw new Error()
        data = identifier.productionOrderRow
        break
      default:
        throw new Error('searchProductionOrderSetting cannot be empty')
    }

    return Encodings.getIdentifiersProducts(data)
  }

  static getIdentifiersProducts(data: ProductionOrderRow) {
    const idfs: ReadIdentifierType[] = []

    // idfs.push({ status: 'waiting', type: 'NFCTag' })
    // idfs.push({ status: 'waiting', type: 'UHFTag' })
    data.product.itemConfiguration.identifiers
      .sort((a, b) => {
        if (a.type > b.type) {
          return 1
        }
        if (b.type > a.type) {
          return -1
        }
        return 0
      })
      .forEach((idf) => {
        for (let index = 0; index < idf.amount; index++) {
          idfs.push({ status: 'waiting', type: idf.type })
        }
      })

    return { data, idfs }
  }

  static async validate(idfs: any[], params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    const res = responseErrorCheck(await api.post<any>('/operations/encoding/validate', params, requestConfig))
    let foundError = false
    idfs
      .sort((a, b) => {
        if (a.type > b.type) {
          return 1
        }
        if (b.type > a.type) {
          return -1
        }
        return 0
      })
      .forEach((idf) => {
        if (!idf.code) {
          return
        }
        const err = res.errors && res.errors.find((idfError) => idfError.ref === idf.code)
        if (err) {
          foundError = true
          idf.status = 'error'
          switch (err.errorCode) {
            case 'ENCODING_ERROR.IDENTIFIER_NOT_FOUND':
              idf.errorMessage = __(T.error.tag_not_found)
              break
            case 'ENCODING_ERROR.IDENTIFIER_TYPE_UNEXPECTED':
              idf.errorMessage = __(T.error.tag_type_unexpected)
              break
            case 'ENCODING_ERROR.IDENTIFIER_ALREADY_ASSOCIATED':
              idf.errorMessage = __(T.error.tag_already_associated)
              break

            default:
              idf.errorMessage = err.errorCode
              break
          }
        } else {
          idf.status = 'confirmed'
        }
      })
    if (foundError) {
      Sounds.error()
    }
    if (
      res &&
      res.errors.findIndex((e) => e.errorCode === 'ENCODING_ERROR.PRODUCTION_ORDER_NOT_FOUND' && !e.ref) > -1
    ) {
      res.status_error = 'ENCODING_ERROR.PRODUCTION_ORDER_NOT_FOUND'
    }
    return res
  }

  static associate(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return api
      .post<any>('/operations/encoding/create', params, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static settings(params?: any, requestConfig?: ApisauceConfig) {
    return api
      .get<any>('/operations/encoding/settings', params, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static verify(
    body: {
      identifiers: {
        code: string
        type: string
      }[]
      configurationId: string
    },
    requestConfig?: ApisauceConfig
  ) {
    return api
      .post<any>('/operations/encoding/verify', body, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }
}
