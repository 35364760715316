import React, { useState } from 'react'
import {
  Page,
  LeftHeader,
  KeyValueRow,
  RightContainer,
  Spacer,
  Box,
  UserInfoModal,
  SettingsModal,
  Image,
  Icons,
} from 'components'
import styled from '@emotion/styled'
import { navigate, refresh, NavigationOptions } from 'shared/router'
import { Routes } from 'pages'
import AppStore from 'AppStore'
import { T, __ } from 'translations/i18n'
import { TmrWorkstation } from 'api/types'
import { showToast } from 'shared/utils'
import RemoteConfig, { InboundConfig, OutboundConfig } from 'shared/RemoteConfig'

export default function Dashboard() {
  const [userModalVisible, setuserModalVisible] = useState(false)
  const [settingsModalVisible, setsettingsModalVisible] = useState(false)
  const selectDefaultWorkstation = async (workstation: TmrWorkstation) => {
    await AppStore.saveDefaultWorkstation(workstation)
    refresh()
  }

  const routeMap: {
    inbound: Routes
    outbound: Routes
    encoding: Routes
    inbound_nochecklist: Routes
    outbound_nochecklist: Routes
  } = {
    inbound: `/inbound/:configCode`,
    outbound: `/outbound/:configCode`,
    encoding: `/encoding/:configCode`,
    inbound_nochecklist: `/inbound/:configCode/create`,
    outbound_nochecklist: `/outbound/:configCode/create`,
  }

  const iconMap = {
    inbound: <Icons.Inbound />,
    outbound: <Icons.Outbound />,
    transfers: <Icons.Transfers />,
  }

  const remoteOp = RemoteConfig.getAllShippingOperations().map((op) => {
    let operation: string = op.operationType
    if (op.hasChecklist === 'no' && 'inboundMode' in op && (op as InboundConfig).inboundMode === 'parcelByParcel') {
      operation = 'inbound_nochecklist'
    }
    if (op.hasChecklist === 'no' && 'outboundMode' in op && (op as OutboundConfig).outboundMode === 'parcelByParcel') {
      operation = 'outbound_nochecklist'
    }
    return {
      operation: routeMap[operation],
      routeParams: { configCode: op.code },
      icon: iconMap[op.icon ?? op.operationType],
      text: op.description,
    }
  })

  const encodingOp = RemoteConfig.getAllEncodingOperations().map((op) => {
    return {
      operation: routeMap.encoding,
      routeParams: { configCode: op.code },
      icon: <Icons.Encoding />,
      text: op.description,
    }
  })

  const userCapabilities = AppStore.loggedUser?.roles?.flatMap((role) => role.capabilities) ?? []
  const isAdmin = AppStore.loggedUser?.admin ?? false
  const staticOp: DashboardButtonProps[] = []
  staticOp.push({ operation: '/pallet', icon: <Icons.Transfers />, text: __(T.titles.pallet_creation) })
  if (isAdmin || userCapabilities.includes('Operation.TagInfo.perform'))
    staticOp.push({ operation: '/tag-info', icon: <Icons.TagInfo />, text: __(T.misc.tag_info) })
  if (isAdmin || userCapabilities.includes('Operation.TransferToZone.perform'))
    staticOp.push({
      operation: '/transfer-to-zone',
      icon: <Icons.TransferToZone />,
      text: __(T.titles.transfer_to_zone),
    })

  const operations: DashboardButtonProps[] = [
    // { operation: '/encoding', icon: <Icons.Encoding />, text: __(T.misc.encoding) },
    ...encodingOp,
    ...staticOp,
    ...remoteOp,
    // {
    //   operation: '/items-list/:operationCode',
    //   routeParams: { operationCode: 'test-1' },
    //   navigationOptions: { state: { testParam: 'Test 1 location state param' } },
    //   icon: <Icons.TagInfo />,
    //   text: 'Test 1',
    // },
    // {
    //   operation: '/items-list/:operationCode',
    //   routeParams: { operationCode: 'test-2' },
    //   icon: <Icons.TagInfo />,
    //   text: 'Test 2',
    // },
    // {
    //   operation: '/items-list/:operationCode',
    //   routeParams: { operationCode: 'test-default' },
    //   icon: <Icons.TagInfo />,
    //   text: 'Test default',
    // },
  ]

  return (
    <Page title="Dashboard" header={null}>
      <Box flex row>
        <LeftHeader>
          <KeyValueRow
            onClick={() => setuserModalVisible(!userModalVisible)}
            label={__(T.misc.user)}
            value={AppStore.loggedUser?.username}
            image={<Icons.Info />}
          />
          <Spacer />
          <KeyValueRow
            label={__(T.misc.workstation)}
            value={AppStore.defaultWorkstation?.code ?? '---'}
            optionModal={{
              onSelect: (item) => selectDefaultWorkstation(item[0]),
              field: 'code',
              options: AppStore.workstations ?? [],
              selected: [AppStore.defaultWorkstation],
              title: __(T.titles.select_workstation),
              searchable: true,
            }}
          />
        </LeftHeader>

        <RightContainer style={{ flex: 1.5, alignItems: 'center' }}>
          <DashView>
            {operations.map((operation, index) => (
              <DashboardButton key={index} {...operation} />
            ))}

            {operations.length % 2 === 1 && <DashboardFakeButton />}
          </DashView>
        </RightContainer>
      </Box>
      <UserInfoModal user={AppStore.loggedUser} onClose={() => setuserModalVisible(false)} visible={userModalVisible} />
      <SettingsModal onClose={() => setsettingsModalVisible(false)} visible={settingsModalVisible} />
    </Page>
  )
}

type DashboardButtonProps = {
  operation?: Routes
  routeParams?: any
  navigationOptions?: NavigationOptions
  icon?: any
  text?: string
}

function DashboardButton({ operation, routeParams, icon, text, navigationOptions }: DashboardButtonProps) {
  const onClick = () => {
    if (!AppStore.defaultWorkstation && !AppStore.emulation) {
      showToast({
        sound: false,
        title: __(T.error.error),
        description: __(T.messages.no_workstation_selected),
        status: 'error',
      })
      if (!AppStore.emulation) return
    }
    operation && navigate(operation, routeParams, navigationOptions)
  }
  return (
    <DashboardItemStyle onClick={onClick}>
      {icon && typeof icon !== 'string' && icon}
      {icon && typeof icon === 'string' && <DashImage alt={text} src={icon} />}
      {text}
    </DashboardItemStyle>
  )
}

const DashboardItemStyle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  width: 276px;
  height: 202px;
  background: #ffffff;
  border: 2px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 5px;
  margin: 30px 20px;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
`

const DashboardFakeButton = styled.div`
  width: 276px;
  height: 202px;
  background: #ffffff;
  border: 2px dashed #eeeeee;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 5px;
  margin: 30px 20px;
  margin-top: 0px;
`

const DashImage = styled(Image)`
  margin-bottom: 10px;
  max-height: 100px;
  max-width: 100px;
`

const DashView = styled(Box)`
  max-width: 700px;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: start;
  padding-top: 50px;
  flex-basis: 150px;
  flex-shrink: 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
`
