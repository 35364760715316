import React, { Component } from 'react'
import { Page, TagCounter, AntennaButton, ItemList } from 'components'
import { TmrItem } from 'api/types'

interface State {
  items: TmrItem[]
  tag?: TmrItem
}

export default class TagInfo extends Component<{}, State> {
  state: State = {
    items: [],
  }

  onItemDecoded = (item) => {
    const { items } = this.state
    if (items.filter((itm) => itm.id === item.id).length > 0) return
    items.unshift(item)
    this.setState({ items })
  }

  clear = () => this.setState({ items: [] })

  render() {
    const { items } = this.state
    return (
      <Page title="TagInfo" enableEmulation>
        <Page.Sidebar>
          <TagCounter detected={items.length} />
          <AntennaButton onClear={this.clear} onItemDecoded={this.onItemDecoded} hideClear={items.length === 0} />
        </Page.Sidebar>

        <Page.Content>
          <ItemList items={items} />
        </Page.Content>
      </Page>
    )
  }
}
