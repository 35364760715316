import React, { Component } from 'react'
import { List, Input, Page, Select, Spacer, ShipmentParcelRow, Icons, Box, Button } from 'components'
import { InboundConfig } from 'shared/RemoteConfig'
import { navigate } from 'shared/router'
import { ShipmentParcelPreview } from 'api/types'
import InboundShipments from 'api/InboundShipments'
import { showToast } from 'shared/utils'
import ShipmentProvider from 'ShipmentProvider'
import { T, __ } from 'translations/i18n'

interface State {
  data: ShipmentParcelPreview[]
  query: string
  originFilter?: ShipmentParcelPreview
  origins?: ShipmentParcelPreview[]
  loading?: boolean
}

interface Props {
  operation: InboundConfig
}

export default class InboundByParcel extends Component<Props, State> {
  operation = this.props.operation

  state: State = {
    data: [],
    query: '',
    loading: true,
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    try {
      const data = await InboundShipments.parcelCounters({
        parcelStates: ['IN_TRANSIT', 'RECEIVE_AWAIT', 'IN_INBOUND', 'INBOUND_ERROR', 'READY_TO_RECEIVE'],
        operationId: this.operation.id,
      })

      this.setState({ loading: false, data: data ?? [], origins: ShipmentProvider.getOrigins(data) })
    } catch (err) {
      this.setState({ loading: false, data: [] })
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  onRowClick = (itm: ShipmentParcelPreview) => {
    if (
      !itm.header.parcelState ||
      !(
        itm.header.parcelState === 'READY_TO_RECEIVE' ||
        itm.header.parcelState === 'IN_TRANSIT' ||
        itm.header.parcelState === 'IN_INBOUND'
      )
    )
      return undefined

    return navigate('/inbound/:configCode/reading/:parcelCode', {
      configCode: this.operation.code,
      parcelCode: itm.header.parcelCode,
    })
  }

  onInputEnter = (input: string) => {
    const { data } = this.state
    this.setState({ query: input })
    const filtered = data.filter(this.filterResults)

    if (filtered.length === 1) {
      this.onRowClick(filtered[0])
    }
  }

  newParcel = () => {
    navigate('/inbound/:configCode/create', { configCode: this.operation?.code })
  }

  filterResults = (result: ShipmentParcelPreview) =>
    (result.header.parcelCode.toLowerCase().includes(this.state.query.toLowerCase()) ||
      result.parcelsCode?.toLowerCase().includes(this.state.query.toLowerCase()) ||
      result.header.shippingCode.toLowerCase().includes(this.state.query.toLowerCase())) &&
    ((this.state.originFilter &&
      result.header.originPlace?.code === this.state.originFilter?.header?.originPlace?.code) ||
      !this.state.originFilter)

  render() {
    const { data, origins, loading } = this.state
    const filtered = data.filter(this.filterResults)
    return (
      <Page title={`${this.operation?.description ?? 'Inbound'}`} onBackPress={() => navigate('/')}>
        <Page.Content bgGrey>
          <Page.TopBar>
            <Input
              startFocus
              style={{ width: 350 }}
              image={<Icons.Barcode />}
              placeholder={__(T.placeholder.search_shipment_parcel)}
              onChange={(query) => this.setState({ query })}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Select
              transparent
              borderColor="transparent"
              containerStyle={{ width: 250 }}
              onSelect={(itm) => this.setState({ originFilter: itm })}
              options={origins ?? []}
              placeholder={__(T.misc.origin)}
              config={{
                value: 'header.originPlace.code',
                label: 'header.originPlace.description',
                secondaryLabel: 'header.originPlace.code',
              }}
            />
            {this.operation.canCreateParcel === 'yes' && (
              <>
                <Box flex />
                <Button title={__(T.misc.new_parcel)} onClick={this.newParcel} />
              </>
            )}
          </Page.TopBar>
          <List
            loading={loading}
            title={`${__(T.misc.parcels_to_receive)} (${filtered.length})`}
            renderItem={(itm) => <ShipmentParcelRow type="inbound" shipmentParcel={itm} onRowClick={this.onRowClick} />}
            data={filtered}
          />
        </Page.Content>
      </Page>
    )
  }
}
