import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Image, Box, Icons } from 'components'
import { TmrProduct } from 'api/types'
import Products from 'api/Products'
import { isNumber } from 'shared/utils'
import { T, __ } from 'translations/i18n'

interface Props {
  product: TmrProduct
  detected?: number
  expected?: number
  unexpected?: number
  noChecklist?: boolean
  hideCounter?: boolean
  noShadow?: boolean
  flex?: boolean
  onDeleteCallback?: (product: TmrProduct) => void
}

export default class GroupedProductRow extends Component<Props> {
  static Skeleton = styled(Box)``

  roundedLabel = (label: string, value?: string) => (
    <Box
      row
      mr={15}
      mb={5}
      style={{
        backgroundColor: '#EDEDED',
        borderRadius: 5,
      }}
      pv={5}
      ph={10}
    >
      <SmallLabel>{label.toUpperCase()}</SmallLabel>
      <SmallValue>{value ?? ' ---'}</SmallValue>
    </Box>
  )

  onDeletePress = () => {
    const { onDeleteCallback, product } = this.props
    if (onDeleteCallback) return () => onDeleteCallback(product)
    return undefined
  }

  render() {
    const {
      product,
      detected,
      expected,
      unexpected,
      noChecklist,
      hideCounter,
      noShadow,
      flex,
      onDeleteCallback,
    } = this.props
    const read = (detected ?? 0) + (unexpected ?? 0)
    const counterValue = expected ? `${read}/${expected}` : `${read}${!noChecklist ? '/0' : ''}`
    return (
      <Row row noShadow={noShadow} flex={flex}>
        {product.code && (
          <Box center style={{ width: 130 }}>
            <ProductImage style={{ width: 125 }} src={Products.getImageUrl(product.code)} />
          </Box>
        )}
        <Separator />
        <Box flex p={20}>
          <Box mb={10}>
            <ProductCode>{product.code}</ProductCode>
            {product.description && <ProductDescription>{product.description}</ProductDescription>}
          </Box>

          <Box row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
            {this.roundedLabel(__(T.misc.size), product.size?.value)}
            {this.roundedLabel(__(T.misc.color), product.color?.value)}
            {this.roundedLabel(__(T.misc.style), product.style?.value)}
            {this.roundedLabel(__(T.misc.material), product.material?.value)}
          </Box>
        </Box>

        {!hideCounter && (isNumber(detected) || isNumber(expected) || isNumber(unexpected)) && (
          <SmallCounter
            mr={30}
            ph={20}
            pv={5}
            detected={detected}
            expected={expected}
            unexpected={unexpected}
            style={{ borderRadius: 10 }}
            noChecklist={noChecklist}
          >
            {counterValue}
          </SmallCounter>
        )}
        {onDeleteCallback && (
          <Box p={30} center onClick={this.onDeletePress()}>
            <Icons.Delete />
          </Box>
        )}
      </Row>
    )
  }
}

const Row = styled(Box)<{
  noShadow?: boolean
}>`
  margin-bottom: 20px;
  border: 2px solid #edecec;
  box-shadow: ${({ noShadow = false }) => {
    if (noShadow) return 'none'
    return '0px 1px 5px rgba(0, 0, 0, 0.08)'
  }};
  border-radius: 10px;
`

const Separator = styled.div`
  width: 2px;
  height: 100%;
  background-color: #edecec;
`

const ProductImage = styled(Image)`
  object-fit: contain;
  width: 100px;
  height: 100px;
  padding: 10px;
`

const ProductDescription = styled.label`
  font-weight: 500;
  font-size: 14pt;
`
const ProductCode = styled.label`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 20pt;
`
const SmallValue = styled.label`
  font-weight: bold;
  font-size: 12pt;
  white-space: nowrap;
`
const SmallLabel = styled.label`
  font-size: 12pt;
  white-space: nowrap;
  margin-right: 5px;
`

const SmallCounter = styled(Box)<{
  detected?: number
  expected?: number
  unexpected?: number
  noChecklist?: boolean
}>`
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  min-width: 70px;
  align-self: center;
  background-color: ${({ detected = 0, expected = 0, unexpected = 0, noChecklist = false }) => {
    if (noChecklist) return '#EDEDED'
    if (unexpected > 0) return '#F2B57D'
    if (detected < expected) return '#EDEDED'
    if (detected === expected) return '#75EBA8'
    return '#75EBA8'
  }};
`
