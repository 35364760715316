import InboundShipments from 'api/InboundShipments'
import Items from 'api/Items'
import OutboundShipments from 'api/OutboundShipments'
import {
  TmrItem,
  ShippingRequestParcel,
  ShipmentParcel,
  GroupedShipmentProduct,
  TmrPlace,
  ShipmentParcelPreview,
  ShipmentParcelTag,
  ShippingConfirmRequest,
  ShipmentParcelFilter,
} from 'api/types'
import AppStore from 'AppStore'
import { InboundConfig, OutboundConfig } from 'shared/RemoteConfig'
import { askUserConfirmation, showToast, uniqueBy } from 'shared/utils'
import { T, __ } from 'translations/i18n'

export type CheckListType = 'UPCS' | 'TAGS' | 'ITEMS'

export default class ShipmentProvider {
  static createShipmentParcel(parcelCode?: string, shipmentCode?: string, destinationPlace?: TmrPlace) {
    return {
      header: {
        parcelCode: parcelCode ?? '',
        shippingCode: shipmentCode ?? '',
        parcelState: 'DRAFT',
        destinationPlace: destinationPlace ?? {},
      },
      expectedCounter: { items: 0, tags: 0, upcs: 0 },
      detectedCounter: { items: 0, tags: 0, upcs: 0 },
      unexpectedCounter: { items: 0, tags: 0, upcs: 0 },
      missingCounter: { items: 0, tags: 0, upcs: 0 },
      expectedItems: [],
      detectedItems: [],
      expectedTags: [],
      detectedTags: [],
      expectedUpcs: [],
      detectedUpcs: [],
      products: {},
    } as ShipmentParcel
  }

  static getShippingRequestParcelsFromShipmentParcels(
    shipmentParcels: ShipmentParcel[],
    configurationId: string,
    destinationPlaceCode?: string,
    checklistTypes: CheckListType = 'ITEMS'
  ) {
    const parcels: ShippingRequestParcel[] = []
    shipmentParcels.forEach((parcel) => {
      const items = checklistTypes === 'ITEMS' ? parcel.detectedItems.map((itm) => itm.id) : []
      //WARNING HERE for checklistype
      const tags: string[] = []
      if (checklistTypes === 'TAGS') {
        parcel.detectedItems.forEach((itm) =>
          itm.itemIdentifiers.filter((idf) => idf.identifierType === 'UHFTag').forEach((idf) => tags.push(idf.code))
        )
        parcel.detectedTags.forEach((tag) => tags.push(tag.tag))
      }
      parcels.push({
        parcelCode: parcel.header.parcelCode,
        shippingParcelDetail: {
          configurationId: configurationId,
          operation: 'replace',
          shippingCode: parcel.header.shippingCode,
          originPlaceCode: AppStore.loggedUser?.place.code,
          destinationPlaceCode: destinationPlaceCode,
          readings: { items: items, tags: tags, upcs: [] },
        },
      })
    })
    return parcels
  }

  static getCheckListTypes(shippingDetails: ShipmentParcel[]) {
    const expectedItems = shippingDetails
      .map((detail) => detail.expectedCounter.items)
      .reduce((acc, curr) => acc + curr, 0)
    const expectedTags = shippingDetails
      .map((detail) => detail.expectedCounter.tags)
      .reduce((acc, curr) => acc + curr, 0)
    const expectedUpcs = shippingDetails
      .map((detail) => detail.expectedCounter.upcs)
      .reduce((acc, curr) => acc + curr, 0)
    const checklistTypes: ('ITEMS' | 'TAGS' | 'UPCS')[] = []
    if (expectedItems > 0) {
      checklistTypes.push('ITEMS')
    }
    if (expectedTags > 0) {
      checklistTypes.push('TAGS')
    }
    if (expectedUpcs > 0) {
      checklistTypes.push('UPCS')
    }
    return checklistTypes
  }

  static clearAllReceivingReadings(shippingDetails: ShipmentParcel[]) {
    shippingDetails.forEach((detail) => {
      detail.detectedUpcs = []
      detail.detectedTags = []
      detail.detectedItems = []
      detail.detectedCounter = { items: 0, upcs: 0, tags: 0 }
      detail.unexpectedCounter = { items: 0, upcs: 0, tags: 0 }
    })
    return ShipmentProvider.initializeGroupedProducts(shippingDetails)
  }

  static processItemForItemsChecklist(
    items: TmrItem[],
    shipmentParcels: ShipmentParcel[],
    groupedProducts: GroupedShipmentProduct[]
  ) {
    items.forEach((itm) => {
      if (!itm || itm.id === undefined) return

      const alreadyReadItem = shipmentParcels.find(
        (shipmentParcel) =>
          shipmentParcel.detectedItems.find((detectedItem) => detectedItem.id === itm.id) ||
          shipmentParcel.detectedTags.find((detectedTag) => detectedTag.tag === itm.epc)
      )

      if (alreadyReadItem) return

      const parcel = shipmentParcels.find((shipmentParcel) =>
        shipmentParcel.expectedItems.find((expectedItem) => expectedItem.id === itm.id)
      )
      if (parcel) {
        parcel.detectedItems.push({
          productCode: itm.upc ?? itm.product.code,
          expected: true,
          ...itm,
          // zoneCode: destinationZone?.code ?? itm.zone?.code,
          // zoneId: destinationZone?.id ?? itm.zone?.id,
        })
        parcel.detectedCounter.items++
      } else {
        const firstParcel = shipmentParcels[0]
        const checkListExist = firstParcel.expectedCounter.tags === 0 && firstParcel.expectedCounter.items === 0
        if ((itm.upc || itm.product.code) && !itm.id) {
          firstParcel.detectedTags.push({
            tag: itm.epc,
            expected: checkListExist,
            productCode: itm.upc ?? itm.product.code,
          })
        } else {
          firstParcel.detectedItems.push({
            productCode: itm.upc ?? itm.product.code,
            expected: checkListExist,
            ...itm,
            // zoneCode: destinationZone.code,
            // zoneId: destinationZone.id,
          })
        }
        if (!shipmentParcels.find((shipmentParcel) => shipmentParcel.products[itm.upc ?? itm.product.code])) {
          firstParcel.products[itm.upc ?? itm.product.code] = itm.product
        }
        firstParcel.unexpectedCounter.items = firstParcel.detectedItems.filter(
          (detectedItem) => !detectedItem.expected
        ).length
        firstParcel.unexpectedCounter.tags = firstParcel.detectedTags.filter(
          (detectedTag) => !detectedTag.expected
        ).length
        firstParcel.detectedCounter.items = firstParcel.detectedItems.length
        firstParcel.detectedCounter.tags = firstParcel.detectedTags.length
      }
      ShipmentProvider.processGroupedProducts(shipmentParcels, groupedProducts, [itm])
    })
  }

  static processItemForUpcsChecklist(
    items: TmrItem[],
    shipmentParcels: ShipmentParcel[],
    groupedProducts: GroupedShipmentProduct[]
  ) {
    items.forEach((itm) => {
      if (!itm || itm.id === undefined) return

      const alreadyReadItem = shipmentParcels.find((shipmentParcel) =>
        shipmentParcel.detectedItems.find((detectedItem) => detectedItem.id === itm.id)
      )

      if (alreadyReadItem) return

      const parcelsWithItemUpc = shipmentParcels.filter((shipmentParcel) =>
        shipmentParcel.expectedUpcs.find((expectedUpc) => expectedUpc.upc === itm?.product?.code ?? itm.upc)
      )
      const parcel = parcelsWithItemUpc.find((shipmentParcel) => {
        const targetUpc = itm.product.code ?? itm.upc
        const detectedItemsByUpc = shipmentParcel.detectedItems.filter(
          (shipmentParcelDetectedItem) =>
            shipmentParcelDetectedItem.upc === targetUpc || shipmentParcelDetectedItem.product.code === targetUpc
        )
        return (
          detectedItemsByUpc.length <
          (shipmentParcel.expectedUpcs.find((expectedUpc) => expectedUpc.upc === targetUpc)?.quantity ?? 0)
        )
      })

      if (parcel) {
        parcel.detectedItems.push({
          productCode: itm.upc ?? itm.product.code,
          expected: true,
          ...itm,
          // zoneCode: destinationZone?.code ?? itm.zone?.code,
          // zoneId: destinationZone?.id ?? itm.zone?.id,
        })
        parcel.detectedCounter.items++
      } else {
        const firstParcel = shipmentParcels[0]
        const checkListExist =
          firstParcel.expectedCounter.tags === 0 &&
          firstParcel.expectedCounter.items === 0 &&
          firstParcel.expectedCounter.upcs === 0
        if ((itm.upc || itm.product.code) && !itm.id) {
          firstParcel.detectedTags.push({
            tag: itm.epc,
            expected: checkListExist,
            productCode: itm.upc ?? itm.product.code,
          })
        } else {
          firstParcel.detectedItems.push({
            productCode: itm.upc ?? itm.product.code,
            expected: checkListExist,
            ...itm,
            // zoneCode: destinationZone.code,
            // zoneId: destinationZone.id,
          })
        }
        if (!shipmentParcels.find((shipmentParcel) => shipmentParcel.products[itm.upc ?? itm.product.code])) {
          firstParcel.products[itm.upc ?? itm.product.code] = itm.product
        }
        if (firstParcel.expectedCounter.upcs > 0) firstParcel.unexpectedCounter.items++ // WARN: validate this
        firstParcel.detectedCounter.items++
      }
      ShipmentProvider.processGroupedProducts(shipmentParcels, groupedProducts, [itm])
    })
  }

  static removeItemsForItemsChecklist(
    items: TmrItem[],
    shipmentParcels: ShipmentParcel[],
    groupedProducts: GroupedShipmentProduct[]
  ) {
    items.forEach((itm) => {
      if (!itm) return

      ShipmentProvider.removeItemsFromGroupedProducts(shipmentParcels, groupedProducts, [itm])

      const parcel = shipmentParcels.find(
        (shipmentParcel) =>
          shipmentParcel.detectedItems.find((detectedItem) => detectedItem.id === itm.id) ||
          shipmentParcel.detectedTags.find((detectedTag) => detectedTag.tag === itm.epc)
      )
      if (parcel) {
        if (itm.id) {
          parcel.detectedItems = parcel.detectedItems.filter((detected) => detected.id !== itm.id)
        } else {
          parcel.detectedTags = parcel.detectedTags.filter((detected) => detected.tag !== itm.epc)
        }
      } else {
        const firstParcel = shipmentParcels[0]
        if ((itm.upc || itm.product.code) && !itm.id) {
          firstParcel.detectedTags = firstParcel.detectedTags.filter((tag) => tag.tag !== itm.epc)
        } else {
          firstParcel.detectedItems = firstParcel.detectedItems.filter((detected) => detected.id !== itm.id)
        }
        if (!shipmentParcels.find((shipmentParcel) => shipmentParcel.products[itm.upc ?? itm.product.code])) {
          firstParcel.products[itm.upc ?? itm.product.code] = itm.product
        }
      }
    })
    this.refreshShipmentCounters(shipmentParcels)
  }

  static refreshShipmentCounters = (shipmentParcels: ShipmentParcel[]) => {
    shipmentParcels.forEach((parcel) => {
      parcel.unexpectedCounter.items = parcel.detectedItems.filter((detectedItem) => !detectedItem.expected).length
      parcel.unexpectedCounter.tags = parcel.detectedTags.filter((detectedTag) => !detectedTag.expected).length
      parcel.detectedCounter.items = parcel.detectedItems.length
      parcel.detectedCounter.tags = parcel.detectedTags.length
    })
  }

  static initializeGroupedProducts(shipmentParcels: ShipmentParcel[]) {
    if (!shipmentParcels || shipmentParcels.length === 0) return []

    const groupedProducts: GroupedShipmentProduct[] = []
    shipmentParcels.forEach((parcel) => {
      parcel.expectedItems.forEach((itm) => {
        const groupedProductIndex = groupedProducts.findIndex((prod) => prod.product.code === itm.productCode)
        if (groupedProductIndex >= 0) {
          return
        }
        groupedProducts.push({
          product: ShipmentProvider.getParcelProduct(itm.productCode, shipmentParcels),
          detected: 0,
          unexpected: 0,
          expected: ShipmentProvider.getExpectedProductCount(itm.productCode, shipmentParcels),
        })
      })
      parcel.expectedUpcs.forEach((upc) => {
        const groupedProductIndex = groupedProducts.findIndex((prod) => prod.product.code === upc.upc)
        if (groupedProductIndex >= 0) {
          return
        }
        groupedProducts.push({
          product: ShipmentProvider.getParcelProduct(upc.upc, shipmentParcels),
          detected: 0,
          unexpected: 0,
          expected: ShipmentProvider.getExpectedProductCount(upc.upc, shipmentParcels),
        })
      })
    })
    return this.processGroupedProducts(shipmentParcels, groupedProducts)
  }

  static processGroupedProducts(
    shipmentParcels: ShipmentParcel[],
    groupedProducts: GroupedShipmentProduct[] = [],
    items?: TmrItem[]
  ) {
    if (!shipmentParcels || shipmentParcels.length === 0) return groupedProducts

    shipmentParcels.forEach((parcel) => {
      const checkListExist =
        parcel.expectedCounter.items > 0 || parcel.expectedCounter.tags > 0 || parcel.expectedCounter.upcs > 0
      parcel.detectedItems
        .filter((detectedItem) => !items || items.find((itm) => itm.id === detectedItem.id))
        .forEach((itm) => {
          const groupedProductIndex = groupedProducts.findIndex((prod) => prod.product.code === itm.productCode)
          if (groupedProductIndex >= 0) {
            if (!checkListExist || itm.expected) {
              groupedProducts[groupedProductIndex].detected++
            } else {
              groupedProducts[groupedProductIndex].unexpected++
            }
            return
          }
          groupedProducts.push({
            product: ShipmentProvider.getParcelProduct(itm.productCode, shipmentParcels),
            detected: !checkListExist || itm.expected ? 1 : 0,
            unexpected: !checkListExist || itm.expected ? 0 : 1,
            expected: ShipmentProvider.getExpectedProductCount(itm.productCode, shipmentParcels),
          })
        })
      parcel.detectedTags
        .filter((tagObj) => !items || items.find((itm) => itm.epc === tagObj.tag))
        .forEach((tagObj) => {
          const groupedProductIndex = groupedProducts.findIndex((prod) => prod.product.code === tagObj.productCode)
          if (groupedProductIndex >= 0) {
            if (tagObj.expected) {
              groupedProducts[groupedProductIndex].detected++
            } else {
              groupedProducts[groupedProductIndex].unexpected++
            }
            return
          }
          groupedProducts.push({
            product: ShipmentProvider.getParcelProduct(tagObj.productCode!, shipmentParcels),
            detected: tagObj.expected ? 1 : 0,
            unexpected: tagObj.expected ? 0 : 1,
            expected: ShipmentProvider.getExpectedProductCount(tagObj.productCode!, shipmentParcels),
          })
        })
    })
    return groupedProducts
  }

  static removeItemsFromGroupedProducts(
    shipmentParcels: ShipmentParcel[],
    groupedProducts: GroupedShipmentProduct[] = [],
    items: TmrItem[]
  ) {
    if (!shipmentParcels || shipmentParcels.length === 0) return groupedProducts

    shipmentParcels.forEach((parcel) => {
      parcel.detectedItems
        .filter((detectedItem) => !items || items.find((itm) => itm.id === detectedItem.id))
        .forEach((itm) => {
          const groupedProductIndex = groupedProducts.findIndex((prod) => prod.product.code === itm.productCode)
          if (groupedProductIndex >= 0) {
            if (itm.expected) {
              groupedProducts[groupedProductIndex].detected--
            } else {
              groupedProducts[groupedProductIndex].unexpected--
            }
            if (
              groupedProducts[groupedProductIndex].detected === 0 &&
              groupedProducts[groupedProductIndex].unexpected === 0
            )
              if (groupedProducts[groupedProductIndex].expected === 0) groupedProducts.splice(groupedProductIndex, 1)
          }
        })
      parcel.detectedTags
        .filter((tagObj) => !items || items.find((itm) => itm.epc === tagObj.tag))
        .forEach((tagObj) => {
          const groupedProductIndex = groupedProducts.findIndex((prod) => prod.product.code === tagObj.productCode)
          if (groupedProductIndex >= 0) {
            if (tagObj.expected) {
              groupedProducts[groupedProductIndex].detected--
            } else {
              groupedProducts[groupedProductIndex].unexpected--
            }
            if (
              groupedProducts[groupedProductIndex].detected === 0 &&
              groupedProducts[groupedProductIndex].unexpected === 0
            )
              if (groupedProducts[groupedProductIndex].expected === 0) groupedProducts.splice(groupedProductIndex, 1)
          }
        })
    })
    return groupedProducts
  }

  static getExpectedProductCount(productCode: string, shipmentParcels: ShipmentParcel[]) {
    return shipmentParcels.reduce((total, current) => {
      const expectedItem = current.expectedItems.filter((itm) => itm.productCode === productCode)
      const expectedUpc = current.expectedUpcs.find((upc) => upc.upc === productCode)
      return total + expectedItem.length + (expectedUpc?.quantity ?? 0)
    }, 0)
  }

  static getParcelProduct(productCode: string, shipmentParcels: ShipmentParcel[]): any {
    return (
      shipmentParcels.find((parcel) => productCode in parcel.products)?.products[productCode] ?? { code: productCode }
    )
  }

  static async decodeFromTagsList(tags: ShipmentParcelTag[]) {
    return Object.values<any>((await Items.batchDecode(tags.map((t) => t.tag))) ?? {})
      .reduce((acc: any[], curr) => {
        if (!acc.find((a) => a.id === curr.id)) {
          acc.push(curr)
        }
        return acc
      }, [])
      .map((item: TmrItem) => {
        const epc = item.itemIdentifiers.find((idf) => idf.identifierType === 'UHFTag')?.code
        return {
          ...item,
          productCode: item.product.code,
          expected: !!tags.find((t) => t.tag === epc),
        }
      })
  }

  static async fetchInboundShipmentParcels(params: ShipmentParcelFilter, operation?: InboundConfig) {
    const inboundShipmentParcels = (await InboundShipments.parcelDetail(params)) ?? []
    const checkListTypes = this.getCheckListTypes(inboundShipmentParcels)
    if (inboundShipmentParcels.length === 0) throw new Error('No parcel found')
    if (operation && operation.hasChecklist === 'yes' && checkListTypes.length === 0)
      throw new Error('No checklist found')

    // Inbound no checklist
    // if (checkListTypes.length !== 1) throw new Error('Number of checklists error')
    if (checkListTypes.length > 1) throw new Error('Number of checklists error')

    const checkListType = checkListTypes.length === 1 ? checkListTypes[0] : 'ITEMS'

    //Add !checkListType for backend problem
    if (checkListType === 'ITEMS' || !checkListType) {
      for await (const parcel of inboundShipmentParcels) {
        parcel.groupedProducts = ShipmentProvider.initializeGroupedProducts([parcel])
      }
    }

    if (checkListType === 'TAGS') {
      const expectedTags: ShipmentParcelTag[] = []
      const detectedTags: ShipmentParcelTag[] = []
      const unexpectedTags: ShipmentParcelTag[] = []
      for await (const parcel of inboundShipmentParcels) {
        parcel.expectedTags.forEach((tag) => expectedTags.push(tag))
        parcel.detectedTags.forEach((tag) => (tag.expected ? detectedTags.push(tag) : unexpectedTags.push(tag)))
        parcel.expectedItems = await ShipmentProvider.decodeFromTagsList(expectedTags)
        parcel.detectedItems = await ShipmentProvider.decodeFromTagsList(detectedTags)

        parcel.expectedCounter.items = parcel.expectedItems.length ?? 0
        parcel.unexpectedCounter.items = parcel.detectedItems.filter((itm) => !itm.expected).length ?? 0
        parcel.detectedCounter.items = parcel.detectedItems.filter((itm) => itm.expected).length ?? 0

        parcel.groupedProducts = ShipmentProvider.initializeGroupedProducts([parcel])
      }
    }
    const groupedProducts = ShipmentProvider.initializeGroupedProducts(inboundShipmentParcels)
    return { inboundShipmentParcels, checkListType, groupedProducts }
  }

  static async fetchOutboundShipmentParcels(
    parcelCode?: string,
    defaultShipmentParcel?: ShipmentParcel,
    operation?: OutboundConfig
  ) {
    if (!parcelCode && !defaultShipmentParcel) throw new Error('No parcel found')

    if (!parcelCode && defaultShipmentParcel)
      return {
        outboundShipmentParcel: defaultShipmentParcel,
        checkListType: 'ITEMS' as 'UPCS' | 'ITEMS' | 'TAGS',
        groupedProducts: defaultShipmentParcel
          ? ShipmentProvider.initializeGroupedProducts([defaultShipmentParcel])
          : [],
      }

    const outboundShipmentParcels = parcelCode
      ? (await OutboundShipments.parcelDetail({ parcelCodes: [parcelCode] })) ?? []
      : []
    const checkListTypes = this.getCheckListTypes(outboundShipmentParcels)

    if (outboundShipmentParcels.length === 0) throw new Error('No parcel found')

    if (operation && operation.hasChecklist === 'yes' && checkListTypes.length === 0)
      throw new Error('No checklist found')

    if (checkListTypes.length > 1) throw new Error('Number of checklists error')

    const checkListType = checkListTypes.length === 1 ? checkListTypes[0] : 'ITEMS'
    const groupedProducts = ShipmentProvider.initializeGroupedProducts(outboundShipmentParcels)
    return { outboundShipmentParcel: outboundShipmentParcels[0], checkListType, groupedProducts }
  }

  static getCounters(
    parcel?: ShipmentParcel,
    checkListType?: 'UPCS' | 'ITEMS' | 'TAGS',
    detectedType?: 'UPCS' | 'ITEMS' | 'TAGS'
  ) {
    if (!parcel || !checkListType) {
      return {
        expected: 0,
        unexpected: 0,
        detected: 0,
      }
    }
    return {
      expected: parcel.expectedCounter.tags + parcel.expectedCounter.items + parcel.expectedCounter.upcs ?? 0,
      unexpected: parcel.unexpectedCounter.tags + parcel.unexpectedCounter.items + parcel.unexpectedCounter.upcs ?? 0,
      detected: parcel.detectedCounter.tags + parcel.detectedCounter.items + parcel.detectedCounter.upcs ?? 0,
    }
  }

  static async confirmInbound(
    shipmentParcels: ShipmentParcel[],
    configurationId: string,
    inboundZoneId: string,
    checkListType: CheckListType = 'ITEMS'
  ) {
    const shippingRequestParcels = ShipmentProvider.getShippingRequestParcelsFromShipmentParcels(
      shipmentParcels,
      configurationId,
      undefined,
      checkListType
    )
    for (let i = 0; i < shippingRequestParcels.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      await InboundShipments.updateReadings(
        shippingRequestParcels[i].parcelCode,
        shippingRequestParcels[i].shippingParcelDetail
      )
      // eslint-disable-next-line no-await-in-loop
      await InboundShipments.confirmParcel(shippingRequestParcels[i].parcelCode, { zone: inboundZoneId })
    }
  }

  static async confirmOutbound(
    shipmentParcels: ShipmentParcel[],
    configurationId: string,
    destinationPlaceCode: string,
    attributes?: ShippingConfirmRequest,
    additionalParcelOperation?: (parcel: ShippingRequestParcel) => Promise<void>
  ) {
    const shippingRequestParcels = ShipmentProvider.getShippingRequestParcelsFromShipmentParcels(
      shipmentParcels,
      configurationId,
      destinationPlaceCode,
      'TAGS'
    )
    for (let i = 0; i < shippingRequestParcels.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      await OutboundShipments.updateReadings(
        shippingRequestParcels[i].parcelCode,
        shippingRequestParcels[i].shippingParcelDetail
      )
      // eslint-disable-next-line no-await-in-loop
      await OutboundShipments.confirmParcel(shippingRequestParcels[i].parcelCode, attributes)

      // eslint-disable-next-line no-await-in-loop
      additionalParcelOperation && (await additionalParcelOperation(shippingRequestParcels[i]))
    }
  }

  static getTotalCounters = (parcel?: ShipmentParcelPreview | ShipmentParcel) => {
    if (!parcel) {
      return {
        expected: 0,
        unexpected: 0,
        detected: 0,
      }
    }

    return {
      expected: parcel.expectedCounter.items + parcel.expectedCounter.tags + parcel.expectedCounter.upcs,
      unexpected: parcel.unexpectedCounter.items + parcel.unexpectedCounter.tags + parcel.unexpectedCounter.upcs,
      detected: parcel.detectedCounter.items + parcel.detectedCounter.tags + parcel.detectedCounter.upcs,
    }
  }

  static processOutboundItemsStates(items: TmrItem[], operationConfig?: OutboundConfig) {
    if (!operationConfig) return

    items.forEach((item) => {
      if (!item?.states) return
      item.__processedStates = []
      item.states.forEach((state) => {
        let processedState
        if (operationConfig?.itemRequireStates?.includes(state)) processedState = 'REQUIRED'
        if (operationConfig?.itemWarningStates?.includes(state)) processedState = 'WARNING'
        if (operationConfig?.itemErrorStates?.includes(state)) processedState = 'ERROR'
        if (operationConfig?.itemIgnoreStates?.includes(state)) processedState = 'IGNORE'

        if (processedState) item.__processedStates?.push({ itemState: state, processedState: processedState })
      })
    })
  }

  static processInboundItemsStates(items: TmrItem[], operationConfig?: InboundConfig) {
    if (!operationConfig) return

    items.forEach((item) => {
      if (!item?.states) return
      item.__processedStates = []
      item.states.forEach((state) => {
        let processedState
        if (operationConfig?.itemRequireStates?.includes(state)) processedState = 'REQUIRED'
        if (operationConfig?.itemWarningStates?.includes(state)) processedState = 'WARNING'
        if (operationConfig?.itemErrorStates?.includes(state)) processedState = 'ERROR'
        if (operationConfig?.itemIgnoreStates?.includes(state)) processedState = 'IGNORE'

        if (processedState) item.__processedStates?.push({ itemState: state, processedState: processedState })
      })
    })
  }

  static createShipmentGroupedParcel(data: ShipmentParcelPreview[] | undefined) {
    const groupedData: ShipmentParcelPreview[] = []
    data?.forEach((d) => {
      const index = groupedData.findIndex((gd) => gd.header.shippingCode === d.header.shippingCode)
      if (index > -1) {
        groupedData[index].expectedCounter = {
          items: groupedData[index].expectedCounter.items + d.expectedCounter.items,
          tags: groupedData[index].expectedCounter.tags + d.expectedCounter.tags,
          upcs: groupedData[index].expectedCounter.upcs + d.expectedCounter.upcs,
        }
        groupedData[index].detectedCounter = {
          items: groupedData[index].detectedCounter.items + d.detectedCounter.items,
          tags: groupedData[index].detectedCounter.tags + d.detectedCounter.tags,
          upcs: groupedData[index].detectedCounter.upcs + d.detectedCounter.upcs,
        }
        groupedData[index].unexpectedCounter = {
          items: groupedData[index].unexpectedCounter.items + d.unexpectedCounter.items,
          tags: groupedData[index].unexpectedCounter.tags + d.unexpectedCounter.tags,
          upcs: groupedData[index].unexpectedCounter.upcs + d.unexpectedCounter.upcs,
        }
        groupedData[index].parcels!.push(d)
        groupedData[index].parcelsCode += `,${d.header.parcelCode}`
      } else {
        groupedData.push({ ...d, parcels: [d], parcelsCode: d.header.parcelCode })
      }
    })
    return groupedData
  }

  static getDestinations = (data) =>
    uniqueBy<ShipmentParcelPreview>(data, 'header.destinationPlace.code')
      .filter((d) => d.header?.destinationPlace?.code)
      .sort((a, b) => a.header!.destinationPlace!.code.localeCompare(b.header!.destinationPlace!.code))

  static getOrigins = (data) =>
    uniqueBy<ShipmentParcelPreview>(data, 'header.originPlace.code')
      .filter((d) => d.header?.originPlace?.code)
      .sort((a, b) => a.header!.originPlace!.code.localeCompare(b.header!.originPlace!.code))

  static getShipmentStatus = (shipmentsDetails: ShipmentParcel[], operation: InboundConfig | OutboundConfig) => {
    let hasMissing = false
    let hasOverQuantity = false
    let hasUnexpected = false
    if (!operation.hasChecklist) {
      return { hasMissing, hasOverQuantity, hasUnexpected }
    }
    shipmentsDetails.forEach((shipment) => {
      const expected = shipment.expectedCounter.items + shipment.expectedCounter.tags + shipment.expectedCounter.upcs
      const detected = shipment.detectedCounter.items + shipment.detectedCounter.tags + shipment.detectedCounter.upcs
      const unexpected =
        shipment.unexpectedCounter.items + shipment.unexpectedCounter.tags + shipment.unexpectedCounter.upcs

      if (detected > expected) hasOverQuantity = true
      if (detected - unexpected < expected) hasMissing = true
      if (unexpected > 0) hasUnexpected = true
    })
    return { hasMissing, hasOverQuantity, hasUnexpected }
  }

  static processShipmentStatus = async (
    operation: OutboundConfig | InboundConfig,
    setError?: (error: string) => void,
    parcel?: ShipmentParcel
  ) => {
    const { canConfirmWithMissing, canConfirmWithOverQuantity, canConfirmWithUnexpected } = operation
    if (!parcel) return false
    const shipmentStatus = ShipmentProvider.getShipmentStatus([parcel], operation)

    let errorMessage = ''
    let warningMessage = ''

    if (shipmentStatus.hasMissing) {
      if (canConfirmWithMissing === 'no') errorMessage = `\r\n${__(T.messages.cant_confirm_with_missing)}`
      if (canConfirmWithMissing === 'withWarning') warningMessage += `\r\n${__(T.messages.products_missing)}`
    }

    if (shipmentStatus.hasOverQuantity) {
      if (canConfirmWithOverQuantity === 'no') errorMessage = `\r\n${__(T.messages.cant_confirm_with_overqty)}`
      if (canConfirmWithOverQuantity === 'withWarning') warningMessage += `\r\n${__(T.messages.products_overqty)}`
    }

    if (shipmentStatus.hasUnexpected) {
      if (canConfirmWithUnexpected === 'no') errorMessage = `\r\n${__(T.messages.cant_confirm_with_unexpected)}`
      if (canConfirmWithUnexpected === 'withWarning') warningMessage += `\r\n${__(T.messages.products_unexpected)}`
    }

    if (errorMessage.length > 0) {
      setError && setError(errorMessage)
      showToast({
        status: 'error',
        description: errorMessage,
        title: __(T.error.error),
      })
      return false
    }

    if (warningMessage.length > 0) {
      const response = await askUserConfirmation(__(T.misc.warning), `${warningMessage}\r\n${__(T.misc.are_you_sure)}`)
      return response
    }
    return true
  }

  static getShipmentsTags = (parcels: ShipmentParcel[]) => {
    const tags: any = []
    parcels?.forEach((parcel) => {
      parcel.detectedTags.forEach((tag) => tags.push({ epc: tag.tag }))
      parcel.detectedItems.forEach((item) =>
        item.itemIdentifiers.forEach((itemIdentifier) => {
          if (itemIdentifier.type === 'UHFTag') tags.push({ epc: itemIdentifier.code })
          if (itemIdentifier.type === 'NFCTag') tags.push({ uid: itemIdentifier.code })
        })
      )
    })
    return tags
  }

  static getInboundStockZone = (operation: InboundConfig) => {
    const stockZones = AppStore.getZonesByType('STOCK')
    if (stockZones.length === 1) return stockZones[0]

    const defaultZoneByOpConfig = operation.defaultStockZoneCode
      ? stockZones.find((zone) => zone.code === operation.defaultStockZoneCode)
      : undefined

    if (defaultZoneByOpConfig) return defaultZoneByOpConfig

    const defaultZoneCodeAttribute = AppStore.defaultWorkstation?.attributes[`${operation.code}-DEFAULT-ZONE`]
    const defaultZoneByWorkstationAttribute = defaultZoneCodeAttribute
      ? stockZones.find((zone) => zone.code === defaultZoneCodeAttribute)
      : undefined

    return defaultZoneByWorkstationAttribute
  }
}
