import React from 'react'
import styled from '@emotion/styled'
import { Box } from 'components'

interface Props {
  quantity?: number
  status?: string
  width?: number
}

export default function PalletStatus({ quantity, status, width }: Props) {
  if (!status) return <></>

  const text = quantity !== undefined ? `${quantity} ${status.toUpperCase()}` : status.toUpperCase()

  return (
    <div style={{ display: 'flex' }}>
      <Container vcenter style={{ maxWidth: width }} status={status}>
        {text}
      </Container>
    </div>
  )
}

const Container = styled(Box)<{ status }>`
  background-color: ${({ status }) => {
    switch (status?.toUpperCase()) {
      case 'OK':
        return '#74F1A9'
      case 'KO':
        return '#FBBF66'
      default:
        return '#C4C4C4'
    }
  }};
  color: black;
  font-weight: bold;
  font-size: 40px;
  padding: 0px 12px;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  text-overflow: ellipsis;
`
