import { ShipmentParcel, ShipmentParcelPreview } from 'api/types'
import { InboundConfig } from 'shared/RemoteConfig'

export async function inboundParcelBeforeConfirm(parcel: ShipmentParcel[], operation: InboundConfig) {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}

export async function inboundParcelAfterConfirm(parcel: ShipmentParcel, operation: InboundConfig) {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}

export async function inboundShipmentBeforeConfirm(
  shipment: ShipmentParcel,
  confirmPayload: any,
  operation: InboundConfig
) {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}

export async function inboundShipmentAfterConfirm(
  parcel: ShipmentParcel,
  confirmResponse: ShipmentParcelPreview,
  operation: InboundConfig
) {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}
