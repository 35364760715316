import React, { Component } from 'react'
import { Box, Form, Icons, Page } from 'components'
import { navigate } from 'shared/router'
import { T, __ } from 'translations/i18n'
import { showToast } from 'shared/utils'

export default class CustomPallet extends Component {
  submit = (data) => {
    if (!data.palletCode) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.field_is_mandatory, { field: __(T.fields.pallet_code) }),
        status: 'error',
      })
      return
    }
    navigate('/pallet/reading/:palletCode', { palletCode: data.palletCode }, { state: data })
  }

  render() {
    return (
      <Page title={__(T.titles.pallet_creation)} onBackPress={() => navigate('/')}>
        <Page.Content bgGrey>
          <Box flex center>
            <Form
              schema={[
                {
                  placeholder: __(T.fields.pallet_code),
                  name: 'palletCode',
                  required: true,
                  image: <Icons.Barcode />,
                  focus: true,
                },
              ]}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
