import React from 'react'
import { Box, Spacer } from 'components'
import { FormLabel, Switch as ChakraSwitch } from '@chakra-ui/react'
import { MotionStyle } from 'framer-motion'

export function Switch({
  name,
  label,
  check,
  onChange,
  containerStyle,
  switchSize,
  labelSize,
}: {
  name: string
  label: string
  check?: boolean
  onChange: (checked: boolean) => void
  containerStyle?: MotionStyle
  switchSize?: 'sm' | 'md' | 'lg'
  labelSize?: number
}) {
  return (
    <Box row vcenter style={containerStyle}>
      <ChakraSwitch
        size={switchSize ?? 'md'}
        onChange={(e) => onChange(e.target.checked)}
        isChecked={check}
        check
        id={name}
      />
      <Spacer />
      <FormLabel fontSize={labelSize} htmlFor={name} mb="0">
        {label}
      </FormLabel>
    </Box>
  )
}
