import { ConfirmModalParams, ModalType, TmrPrinter, TmrUser, TmrWorkstation, TmrZone } from 'api/types'
import Auth from 'api/Auth'
import Storage from 'shared/Storage'
import Workstations from 'api/Workstations'
import { showToast, __isDev } from 'shared/utils'
import { reloadApp } from 'shared/router'
import Zones from 'api/Zones'
import api from 'api/api'
import { changeLanguage as changeLanguage_i18n, T, __ } from 'translations/i18n'
import RemoteConfig from 'shared/RemoteConfig'
import Printers from 'api/Printers'

export interface Emulation {
  name: string
  value: string
}

class AppStore {
  authToken?: string

  defaultWorkstation?: TmrWorkstation
  defaultPrinter?: TmrPrinter
  defaultStockZone?: TmrZone

  workstations?: TmrWorkstation[]
  printers: TmrPrinter[] = []

  loggedUser?: TmrUser

  zones?: TmrZone[]

  language = 'it'

  languages = ['it', 'en']

  emulation = __isDev

  dailyItems = 0

  emulationList: Emulation[] = []

  confirmModalParams: ConfirmModalParams = { title: '', message: '', onConfirm: () => {}, onCancel: () => {} }
  toggleConfirmModalHandler?: (show: boolean) => void

  openModal?: (modal: ModalType) => void
  closeModal?: (id: string) => void

  reloadRouting!: () => void

  async loadInitalData() {
    //check language
    this.language = await Storage.load('language', 'it')
    changeLanguage_i18n(this.language)

    //check if is present a custom endpoint
    api.setBaseURL(await Storage.load('endpoint', api.getBaseURL()))

    await this.loadAuthToken()
    if (this.authToken) {
      try {
        this.loggedUser = await Auth.loggedUser()
        if (this.loggedUser) {
          this.workstations = await Workstations.searchByPlaceId(this.loggedUser.place.id)
          try {
            this.printers = await Printers.search()
          } catch (e) {
            //ignore
          }

          if (this.workstations && this.workstations.length === 1) {
            await this.saveDefaultWorkstation(this.workstations[0])
          } else if (this.workstations && this.workstations.length > 1) {
            const dWorkstation = await this.getDefaultWorkstation()
            this.defaultWorkstation = this.workstations?.find((w) => w.id === dWorkstation?.id)
          }

          this.defaultPrinter = await this.getDefaultPrinter()

          this.zones = await Zones.search({ 'place.id': this.loggedUser.place.id })

          if (this.zones && this.getZonesByType('STOCK').length === 1) {
            await this.saveDefaultStockZone(this.getZonesByType('STOCK')[0])
          } else if (this.zones && this.getZonesByType('STOCK').length > 1) {
            const dStockZone = await this.getDefaultStockZone()
            this.defaultStockZone = this.getZonesByType('STOCK').find((w) => w.id === dStockZone?.id)
          }

          this.dailyItems = await Storage.load(`dailyItems`, 0)

          this.emulation = await Storage.load(`emulation`, false)

          this.emulationList = await this.loadEmulationList()

          // load remote operations configurations
          await RemoteConfig.load()
        }
      } catch (err) {
        showToast({
          title: __(T.error.error),
          description: err?.message ?? 'Check capabilities',
          status: 'error',
        })
        this.logout()
        throw err
      }
    }
  }

  async getEndpoint() {
    return Storage.load('endpoint', api.getBaseURL())
  }

  async changeLanguage(language: string) {
    this.language = language
    await Storage.save('language', language)
    changeLanguage_i18n(language)
  }

  async setEndpoint(endpoint) {
    await Storage.save('endpoint', endpoint)
    reloadApp()
  }

  getZonesByType(type: 'STOCK' | 'SOLD' | 'INBOUND' | 'LOST') {
    if (!this.zones) return []

    return this.zones.filter((zone) => zone.zoneType === type)
  }

  async enableEmulation(emulation = true) {
    this.emulation = emulation
    await Storage.save(`emulation`, emulation)
  }

  async increaseDailyItems() {
    this.dailyItems++
    await Storage.save(`dailyItems`, this.dailyItems)
  }

  async resetDailyItems() {
    this.dailyItems = 0
    await Storage.save(`dailyItems`, 0)
  }

  async saveDefaultWorkstation(workstation: TmrWorkstation) {
    this.defaultWorkstation = workstation
    await Storage.save(`defaultWorkstation`, workstation)
    return this.defaultWorkstation
  }

  async getDefaultWorkstation() {
    this.defaultWorkstation = await Storage.load(`defaultWorkstation`, this.defaultWorkstation)
    return this.defaultWorkstation
  }

  async saveDefaultStockZone(stockZone: TmrZone) {
    this.defaultStockZone = stockZone
    await Storage.save(`defaultStockZone`, stockZone)
    return this.defaultStockZone
  }

  async getDefaultStockZone() {
    this.defaultStockZone = await Storage.load(`defaultStockZone`, this.defaultStockZone)
    return this.defaultStockZone
  }

  async saveDefaultPrinter(printer: TmrPrinter) {
    this.defaultPrinter = printer
    await Storage.save(`defaultPrinter`, printer)
    return this.defaultPrinter
  }

  async getDefaultPrinter() {
    this.defaultPrinter = await Storage.load(`defaultPrinter`, this.defaultPrinter)
    return this.defaultPrinter
  }

  async saveAuthToken(token?: string) {
    this.authToken = token
    await Storage.save(`authToken`, token)
    return this.authToken
  }

  async logout() {
    // await Auth.logout()
    Storage.remove(`authToken`)
    this.authToken = undefined
    Storage.remove(`defaultWorkstation`)
    this.defaultWorkstation = undefined
    Storage.remove(`defaultStockZone`)
    this.defaultStockZone = undefined
    Storage.remove(`defaultPrinter`)
    this.defaultPrinter = undefined
    Storage.remove(`dailyItems`)
    Storage.remove(`emulation`)
    this.loggedUser = undefined
    if (this.reloadRouting) this.reloadRouting()
    else reloadApp()
  }

  async loadAuthToken() {
    this.authToken = await Storage.load(`authToken`, this.authToken)
    Auth.setHeaderAccessToken(this.authToken!)
    return this.authToken
  }

  async saveEmulationList(emulationList: Emulation[]) {
    this.emulationList = emulationList
    await Storage.save(`emulationList`, emulationList)
    return this.emulationList
  }

  async loadEmulationList() {
    this.emulationList = await Storage.load(`emulationList`, this.emulationList)
    return this.emulationList
  }

  setConfirmModalHandler(handler) {
    this.toggleConfirmModalHandler = handler
  }

  showConfirmModal(title: string, message: string, onConfirm: () => void, onCancel?: () => void) {
    this.confirmModalParams = { title, message, onConfirm, onCancel: onCancel ?? undefined }
    this.toggleConfirmModalHandler?.(true)
  }

  hideConfirmModal() {
    this.toggleConfirmModalHandler?.(false)
  }
}

export default new AppStore()
