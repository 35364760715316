import React, { Component } from 'react'
import { Box, Modal, Spacer, KeyValueRow, TextBox } from 'components'
import { CustomTmrItem } from 'api/types'
import { T, __ } from 'translations/i18n'

interface Props {
  item: CustomTmrItem
  onClose: () => void
  visible: boolean
}

export default class PalletParcelInfoModal extends Component<Props> {
  render() {
    const { item, onClose, visible } = this.props

    return (
      <Modal size="2xl" onClose={onClose} visible={visible}>
        <Box vcenter row mb={30}>
          <Modal.Title>
            {__(T.titles.pallet_package, { orderNumber: item.orderNumber ?? __(T.misc.unknown) })}
          </Modal.Title>
        </Box>

        <Box style={{ marginBottom: 10 }}>
          <Box row>
            <KeyValueRow style={{ flex: 1 }} variant="grey" label={__(T.misc.order)} value={item.orderNumber} />
            <Spacer />
            <KeyValueRow style={{ flex: 1 }} variant="grey" label={__(T.misc.tracking)} value={item.trackingCode} />
          </Box>
          <Spacer />
          <Box row>
            <KeyValueRow style={{ flex: 1 }} variant="grey" label={__(T.misc.country)} value={item.country} />
            <Spacer />
            <KeyValueRow
              style={{ flex: 1 }}
              variant="grey"
              label={__(T.fields.carrier_code)}
              value={item.carrierCode}
            />
          </Box>
          <Spacer />
          <Box row>
            <KeyValueRow
              style={{ flex: 1 }}
              variant="grey"
              label={__(T.fields.carrier_desc)}
              value={item.carrierDesc}
            />
          </Box>
          <Spacer />
          {!!item?.parcelError && <TextBox text={item?.parcelError} type="error" />}
        </Box>
      </Modal>
    )
  }
}
